import React from 'react'
import {DialogContent} from '@mui/material'
import styled from 'styled-components'

import {useSelector} from 'react-redux'

import UploaderImagineStageUploading from './UploaderImagineStageUploading'
import UploaderDialogFooterImagine from './UploaderDialogFooterImagine'
import UploaderImagineStageProjectCollectionSelect from './UploaderImagineStageProjectCollectionSelect'
import UploaderImagineStageFileSelector from './UploaderImagineStageFileSelector'
import UploaderImagineStageResults from './UploaderImagineStageResults'

import DialogContentContainer from './styled/DialogContentContainer'
import {StoreState} from '../reducers/appStore'
import UploaderImagineStageMetadataForm from './UploaderImagineStageMetadataForm'

const FormContainer = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
`

/**
 * Uploader used for the Imagine API (v3)
 *
 * Selects the stage of the uploader workflow dialog based on the imagineUploaderReduer
 */
function UploaderImagine() {
  const workflowStage = useSelector<StoreState>(state => state.imageUploader.uploadWorkflowStage)

  return (
    <>
      <DialogContentContainer>
        <DialogContent>
          <FormContainer>
            {workflowStage === 0 && <UploaderImagineStageFileSelector />}
            {workflowStage === 1 && <UploaderImagineStageProjectCollectionSelect />}
            {workflowStage === 2 && <UploaderImagineStageMetadataForm />}
            {workflowStage === 3 && <UploaderImagineStageUploading />}
            {workflowStage === 4 && <UploaderImagineStageResults />}
          </FormContainer>
        </DialogContent>
      </DialogContentContainer>
      <UploaderDialogFooterImagine />
    </>
  )
}

export default UploaderImagine
