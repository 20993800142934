import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {GeoJSON, Map as LeafletMap} from 'react-leaflet'
import * as turf from '@turf/turf'
import {cellToBoundary, CoordPair, cellToParent} from 'h3-js'
import BingBasemap from '../BingBasemap'
import {gradientColorsGenerator, H3Data, webMToH3ResolutionMapping} from './utils'

const MapContainer = styled.div`
  display: flex;
  height: 90%;
  #h3Map {
    width: 100%;
    border: 0;
    margin: 2px;
    z-index: 1;
    min-height: 300px;
  }

  .leaflet-tooltip {
    background-color: transparent;
    border: 0;
    border-radius: 3px;
    color: #fff;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    box-shadow: none;
  }

  .leaflet-layer,
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out,
  .leaflet-control-attribution {
    filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
  }
`

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export default function ImagineCollectionHexMap(props: {h3SourceData: H3Data[]}) {
  const h3SourceData: H3Data[] = props.h3SourceData
  const [mapProps, setMapProps] = useState({latLngBounds: null, minZoom: 1})
  const [itemGeometry, setItemGeometry] = useState(null)
  const [layerKey, setLayerKey] = useState('')
  const [hexLayerMinCount, setHexLayerMinCount] = useState(0)
  const [hexLayerMaxCount, setHexLayerMaxCount] = useState(0)
  const [currentMapZoom, setCurrentMapZoom] = useState(1)

  const previewLocationMapRef = useRef()
  const gradientColors = gradientColorsGenerator('#0000FF', '#FF0000', 15)

  function getHexColor(count: number) {
    const idx = Math.floor(
      ((count - hexLayerMinCount) * 15) / (hexLayerMaxCount - hexLayerMinCount)
    )
    return gradientColors[Math.min(idx, 14)]
  }

  useEffect(() => {
    console.log("h3SourceData length", h3SourceData.length)
    renderHexLayer(true)
  }, [h3SourceData.length])

  const groupByParentH3 = async (h3SourceData: H3Data[], desiredH3Res: number): Promise<{}> => {
    const groupedH3Data = {}
    h3SourceData.forEach(item => {
      const parentH3Index = cellToParent(item.h3index, desiredH3Res)
      if (groupedH3Data[parentH3Index]) {
        groupedH3Data[parentH3Index] += item.count
      } else {
        groupedH3Data[parentH3Index] = item.count
      }
    })
    return groupedH3Data
  }

  const renderHexLayer = (setExtent: boolean) => {
    if (!previewLocationMapRef.current || !previewLocationMapRef.current.leafletElement) {
      return
    }

    let mapBounds = null
    try {
      mapBounds = previewLocationMapRef.current.leafletElement.getBounds()
    } catch (e) {
      // ignore error as mapBounds is not set on initial map load
    }

    let extent: any = undefined
    const currentMapRes = previewLocationMapRef.current.leafletElement._zoom
    setCurrentMapZoom(currentMapRes)

    previewLocationMapRef.current.leafletElement.attributionControl = false
    const desiredH3Res = webMToH3ResolutionMapping[currentMapRes]
    //console.log(desiredH3Res, currentMapRes)

    groupByParentH3(h3SourceData, desiredH3Res).then(groupedH3Data => {
      let featureLayer = []
      let minCount = 1e6
      let maxCount = 0

      for (const parentH3Idx of Object.keys(groupedH3Data)) {
        const hexBoundary = cellToBoundary(parentH3Idx).map((cp: CoordPair) => {
          return [cp[1], cp[0]]
        })
        //close the polygon
        hexBoundary.push(hexBoundary[0])

        const hexPoly = turf.polygon([hexBoundary])
        if (mapBounds) {
          const sw = mapBounds.getSouthWest()
          const ne = mapBounds.getNorthEast()

          let mapBoundsTurfPoly = turf.bboxPolygon([sw.lng, sw.lat, ne.lng, ne.lat])
          if (turf.booleanDisjoint(hexPoly, mapBoundsTurfPoly)) {
            continue
          }
        }

        try {
          if (!extent) {
            extent = hexPoly
          } else {
            extent = turf.union(extent, hexPoly)
          }
        } catch (e) {
          console.error('turf union error', hexBoundary)
          console.error(e)
        }

        const feature = {
          type: 'Feature',
          properties: {
            count: '' + groupedH3Data[parentH3Idx],
            key: '' + parentH3Idx,
          },
          geometry: {
            type: 'Polygon',
            coordinates: [hexBoundary],
          },
        }

        minCount = Math.min(minCount, groupedH3Data[parentH3Idx])
        maxCount = Math.max(maxCount, groupedH3Data[parentH3Idx])

        featureLayer.push(feature)
      }

      setHexLayerMinCount(minCount)
      setHexLayerMaxCount(maxCount)

      if (extent) {
        const bbox = turf.bbox(extent)
        if (setExtent) {
          setMapProps({
            latLngBounds: [
              [bbox[1], bbox[0]],
              [bbox[3], bbox[2]],
            ],
          })
        }

        const geojsonLayer = {
          type: 'FeatureCollection',
          features: featureLayer,
        }

        setLayerKey(uuidv4())
        setItemGeometry(geojsonLayer)
      }
    })
  }

  useEffect(() => {
    if (previewLocationMapRef.current && mapProps) {
      const mapElement = previewLocationMapRef.current.leafletElement
      if (mapProps.latLngBounds) {
        mapElement.fitBounds(mapProps.latLngBounds)
      }
    }
  }, [mapProps])

  const zoomend = () => {
    if (previewLocationMapRef && previewLocationMapRef.current) {
      setCurrentMapZoom(previewLocationMapRef.current.leafletElement._zoom)
    }
    // don't set map bounds after render since that will re-trigger zoom event
    renderHexLayer(false)
  }

  return (
    <MapContainer>
      <LeafletMap
        id="h3Map"
        ref={previewLocationMapRef}
        {...mapProps}
        onZoomEnd={zoomend}
        attributionControl={false}
      >
        <BingBasemap />
        <GeoJSON
          key={layerKey}
          data={itemGeometry}
          onEachFeature={(feature, leafletLayer) => {
            leafletLayer
              .bindTooltip(feature.properties.count, {
                permanent: false,
                direction: 'center',
                className: 'my-labels',
              })
              .openTooltip()
          }}
          style={feature => {
            const cnt = parseInt(feature.properties.count)
            const color = getHexColor(cnt)
            const lineColor = hexLayerMaxCount == hexLayerMinCount ? '#ffffff' : color
            return {
              color: lineColor,
              weight: 1,
              fillColor: color,
              fillOpacity: 0.5,
            }
          }}
        ></GeoJSON>
      </LeafletMap>
    </MapContainer>
  )
}

ImagineCollectionHexMap.propTypes = {
  h3SourceData: PropTypes.array.isRequired,
}
