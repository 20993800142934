import React, {useContext, useEffect, useState} from 'react'
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography} from '@mui/material'
import {useSelector, useDispatch} from 'react-redux'
import styled from 'styled-components'

import {
  setProject,
  setCollection,
  addAvailableWorkflowStages,
  setAvailableWorkflowStages,
  indicateWorkflowFailure,
} from '../actions/imageUploaderAction'
import {EntitlementLevel, imagineServiceContext} from '../context/imagineServiceContext'
import {telemetry} from '../utilities/telemetry'
import {StoreState} from '../reducers/appStore'

const Container = styled.section`
  width: 80%;
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
`

const StyledFormControl = styled(FormControl)`
  margin-bottom: 20px;
`

function UploaderImagineStageProjectCollectionSelect() {
  const [projectList, setProjectList] = useState([])
  const [collectionList, setCollectionList] = useState([])
  const dispatch = useDispatch()
  const {imagineSdk, retrieveProjects, retrieveCollections} = useContext(imagineServiceContext)

  const setProjectAction = (project: string) => dispatch(setProject(project))
  const setCollectionAction = (collection: string) => dispatch(setCollection(collection))
  const addWorkflowStages = (stages: number[]) => dispatch(addAvailableWorkflowStages(stages))
  const setWorkflowStages = (stages: number[]) => dispatch(setAvailableWorkflowStages(stages))
  const setDialogError = (message: string) => {
    dispatch(indicateWorkflowFailure(message))
    setWorkflowStages([4])
  }

  const selectedProject = useSelector<StoreState, string>(
    state => state.imageUploader.selectedProject
  )
  const selectedCollection = useSelector<StoreState, string>(
    state => state.imageUploader.selectedCollection
  )

  useEffect(() => {
    if (!imagineSdk) {
      return
    }

    async function retrieveCollectionList() {
      const collections = await retrieveCollections()
      const sortedCollectionList = collections.map(collection => collection.id).sort()
      setCollectionList(sortedCollectionList)
    }

    retrieveCollectionList().catch(e => {
      const additionalErrorDetails =
        'There was an error retrieving the set of collections from Imagine.'
      telemetry.error(e, {
        details: additionalErrorDetails,
        type: 'uploader',
        subtype: 'ImagineServiceUploader',
      })
      setDialogError(additionalErrorDetails)
    })
  }, [imagineSdk])

  useEffect(() => {
    if (!imagineSdk) {
      return
    }

    async function retrieveProjectList() {
      const listOfProjects = []
      const projects = await retrieveProjects()
      for (const project of projects) {
        const getEntitlements = await project.entitlements()
        listOfProjects.push({
          name: project.id,
          hasEntitlement: getEntitlements.currentUserHasEntitlement(EntitlementLevel.PUBLISH),
        })
      }

      function nameSort(a: {name: string}, b: {name: string}) {
        return a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      }

      const enabledProjects = listOfProjects.filter(i => i.hasEntitlement)
      const disabledProjects = listOfProjects.filter(i => !i.hasEntitlement)

      return [...enabledProjects.sort(nameSort), ...disabledProjects.sort(nameSort)]
    }

    retrieveProjectList()
      .then(setProjectList)
      .catch(e => {
        const additionalErrorDetails =
          'There was an error retrieving the set of projects from Imagine.'
        telemetry.error(e, {
          details: additionalErrorDetails,
          type: 'upload',
          subtype: 'ImagineServiceUploader',
        })
        setDialogError(additionalErrorDetails)
      })
  }, [imagineSdk])

  useEffect(() => {
    async function projectAndCollectionCheck() {
      const collection = await imagineSdk.fetchCollection({id: selectedCollection})
      const schema = await collection.itemSchema()
      const schemaProperties = schema.jsonSchema.properties.properties.properties
      const requiredProperties = schema.jsonSchema.properties.properties.required
      for (const property of requiredProperties) {
        if (schemaProperties[property].type === 'object') {
          telemetry.error(new Error('The uploader does not support "object" properties'), {
            details: JSON.stringify(schemaProperties[property]),
            type: 'upload',
            subtype: 'ImagineServiceUploader',
          })
          dispatch(
            indicateWorkflowFailure(
              `The uploader does not support "object" properties. Occurred on property: '${property}'`
            )
          )
          setWorkflowStages([1])
          return
        }
      }
      addWorkflowStages([2])
    }

    if (selectedProject && selectedCollection) {
      telemetry.info(`Selected project and collection.`, {
        type: 'upload',
        subtype: 'ImagineServiceUploader',
        metadata: {
          collection: selectedCollection,
          project: selectedProject,
        },
      })
      projectAndCollectionCheck().catch(err => {
        const additionalErrorDetails = 'There was an error checking project and collection.'
        telemetry.error(err, {
          details: additionalErrorDetails,
          type: 'upload',
          subtype: 'ImagineServiceUploader',
        })
        setDialogError(additionalErrorDetails)
      })
    }
  }, [selectedCollection, selectedProject])

  return (
    <>
      <Container>
        <Typography variant="h6">Set Item Project and Collection</Typography>
        <br />
        <StyledFormControl>
          <InputLabel id="uploadProjectSelect">Project *</InputLabel>
          <Select
            labelId="uploadProjectSelect"
            value={selectedProject}
            onChange={e => setProjectAction(e.target.value)}
            variant="standard"
          >
            {projectList &&
              projectList.map(({name, hasEntitlement}, index) => (
                <MenuItem
                  key={`project-dropdown-${name}-${index}`}
                  value={name}
                  disabled={!hasEntitlement}
                >
                  {name}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>
            Projects are groupings that are used for controlling access.
          </FormHelperText>
        </StyledFormControl>
        <FormControl>
          <InputLabel id="uploadCollectionSelect">Collection *</InputLabel>
          <Select
            labelId="uploadCollectionSelect"
            value={selectedCollection}
            onChange={e => setCollectionAction(e.target.value)}
            helpertext="test"
            variant="standard"
          >
            {collectionList &&
              collectionList.map(i => (
                <MenuItem key={`collection-dropdown-${i}`} value={i}>
                  {i}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>
            Collections dictate the metadata schema of an Item and its assets.
          </FormHelperText>
        </FormControl>
      </Container>
    </>
  )
}

export default UploaderImagineStageProjectCollectionSelect
