import React, {useContext, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import styled from 'styled-components'
import {CircularProgress, Typography} from '@mui/material'

import {
  setAvailableWorkflowStages,
  setUploaderWorkflowStage,
  indicateWorkflowSuccess,
  indicateWorkflowFailure,
} from '../actions/imageUploaderAction'

import {imagineServiceContext} from '../context/imagineServiceContext'
import {telemetry} from '../utilities/telemetry'
import {StoreState} from '../reducers/appStore'
import {UploaderMetadataContent} from '../reducers/imageUploaderReducer'

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 450px;

  .MuiTypography-root {
    margin-top: 35px;
  }
`

const insufficientEntitlementRe = /The PUBLISH entitlement is required/
const missingMetadataRe = /Some required metadata properties are missing or invalid/
const serverErrorRe = /Added Item could not be retrieved from the server/
const genericErrorRe = /Item addition in Project .* failed - (.*)$/
const duplicateAssetChecksumRe = /.*Checksum .* already exists on asset.*/

type WorkflowSuccessData = {id: string; project: string}

function UploaderImagineStageUploading() {
  const project = useSelector<StoreState, string>(state => state.imageUploader.selectedProject)
  const collection = useSelector<StoreState, string>(
    state => state.imageUploader.selectedCollection
  )
  const file = useSelector<StoreState, File | null>(state => state.imageUploader.itemAssetFile)
  const metadata = useSelector<StoreState, UploaderMetadataContent>(
    state => state.imageUploader.metadataContent
  )

  const {imagineSdk} = useContext(imagineServiceContext)

  const dispatch = useDispatch()

  const setStage = (stage: number) => dispatch(setUploaderWorkflowStage(stage))
  const setWorkflowStages = (stages: number[]) => dispatch(setAvailableWorkflowStages(stages))
  const setResultingId = (args: WorkflowSuccessData) => dispatch(indicateWorkflowSuccess(args))
  const setDialogError = (message: string) => dispatch(indicateWorkflowFailure(message))

  const progressToNextStage = () => {
    setWorkflowStages([4])
    setStage(4)
  }

  useEffect(() => {
    if (!imagineSdk) {
      return
    }

    const uploadImage = async () => {
      const selectedProject = await imagineSdk.fetchProject({id: project}).catch(e => {
        const additionalErrorDetails = `Unable to retrieve project ${project}.`
        telemetry.error(e, {
          details: additionalErrorDetails,
          type: 'upload',
          subtype: 'ImagineServiceUploader',
        })
        throw e
      })

      const selectedCollection = await imagineSdk.fetchCollection({id: collection}).catch(e => {
        const additionalErrorDetails = `Unable to retrieve collection ${collection}.`
        telemetry.error(e, {
          details: additionalErrorDetails,
          type: 'upload',
          subtype: 'ImagineServiceUploader',
        })
        throw e
      })

      // Prevent transmission of empty arrays in properties
      const sanitizedProperties = {}

      Object.entries(metadata.properties).forEach(([key, value]) => {
        if (!(Array.isArray(value) && value.length === 0)) {
          sanitizedProperties[key] = value
        }
      })

      const createItemOptions = {
        project: selectedProject,
        properties: sanitizedProperties,
        createAssets: [{file, id: 'image', roles: ['data']}],
      }

      selectedCollection
        .createItem(createItemOptions)
        .then(result => {
          setResultingId({id: result.id, project})
        })
        .catch(e => {
          const createAssetsOptions = createItemOptions.createAssets.map(asset => ({
            file: {
              name: file.name,
              size: file.size,
              lastModified: file.lastModified,
            },
            id: asset.id,
            roles: asset.roles,
          }))
          telemetry.error(e, {
            details: 'Error creating item.',
            type: 'upload',
            subtype: 'ImagineServiceUploader',
            metadata: {
              collection: selectedCollection.id,
              createItemOptions: {
                project: selectedProject.id,
                properties: sanitizedProperties,
                createAssets: createAssetsOptions,
              },
            },
          })
          if (insufficientEntitlementRe.test(e.message)) {
            setDialogError(
              `You do not have entitlement to publish to ${project}. Please contact Location360 Support if you believe this to be in error.`
            )
          } else if (missingMetadataRe.test(e.message)) {
            /*
               ! If a user has made it to this point, it means that something in the AssetMetadataForm validation has failed.
               ! This would require further intervention at the development level.
            */
            setDialogError(
              'The upload is missing required metadata. Please contact Location360 Support for assistance.'
            )
          } else if (serverErrorRe.test(e.message)) {
            // Something bad has happened at the server.
            setDialogError(
              'A server error has occured. Please contact Location360 Support for assistance if this continues to occur.'
            )
          } else if (duplicateAssetChecksumRe.test(e.message)) {
            setDialogError(e.message)
          } else if (genericErrorRe.test(e.message)) {
            setDialogError(e.message)
          } else {
            setDialogError('An unknown error has taken place. Please contact Location360 Support.')
          }
        })
        .finally(progressToNextStage)
    }

    if (!collection || !project || !metadata || !file) {
      setDialogError('An error in the uploader UI has occured. Please contact Location360 Support.')
      progressToNextStage()
    } else {
      uploadImage().catch(err => {
        telemetry.error(err, {
          type: 'upload',
          subtype: 'ImagineServiceUploader',
        })
        setDialogError(
          'An error in the uploader UI has occured. Please contact Location360 Support.'
        )
      })
    }
  }, [])

  return (
    <Container>
      <CircularProgress size={120} />
      <Typography variant="body1" align="center">
        Uploading item to the Imagine Service.
      </Typography>
    </Container>
  )
}

export default UploaderImagineStageUploading
