import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import {Paper, Typography} from '@mui/material'

import useToastNotification from '../hooks/useToastNotification'

import DialogMultiTagEditSelectionTable from './DialogMultiTagEditSelectionTable'
import {telemetry} from '../utilities/telemetry'

/**
 * Trigger button and workflows for modifying multiple tags to items
 */
function DialogMultiTagEdit(props) {
  const {handleClose, selectedItems, untaggableIds} = props

  const [tagHistogram, setTagHistogram] = useState({})

  const {errorNotification, successNotification, warningNotification} = useToastNotification()

  // Loads a histogram of tags based on selected items. Reset dialog on close.
  useEffect(() => {
    async function loadTags() {
      const tagCount = {}

      for await (const item of selectedItems) {
        // Skip the item if a user can't alter it
        if (untaggableIds.indexOf(item.id) === -1) {
          const properties = item.properties

          const tags = properties?.['tags:tags'] ?? []

          for (const tag of tags) {
            tagCount[tag] = tagCount[tag] ? tagCount[tag] + 1 : 1
          }
        }
      }
      setTagHistogram(tagCount)
    }

    loadTags()
  }, [selectedItems])

  async function editTags(tagsForModification) {
    const listOfTags = Object.entries(tagsForModification)

    let errorsCount = 0
    const alterTagsPromise = selectedItems.map(async item => {
      try {
        const metadata = item.properties
        const tags = metadata?.['tags:tags'] ?? []

        if (tags.length === 0) return

        let triggerSave = false
        for (const tagForModification of listOfTags) {
          const [originalTag, alteredTag] = tagForModification
          const index = tags.indexOf(originalTag)
          if (index !== -1) {
            triggerSave = true
            tags[index] = alteredTag
          }
        }
        // Skip API call if there is nothing to modify
        if (!triggerSave) return

        await item.updateMetadata({properties: {['tags:tags']: tags}})
      } catch (e) {
        errorsCount++
        const additionalErrorDetails = `Error in updating tags for item ${item.id}.`
        telemetry.error(e, {details: additionalErrorDetails})
      }
    })

    await Promise.allSettled(alterTagsPromise)

    if (errorsCount === 0) {
      successNotification('Tag modification successful')
    } else if (errorsCount < selectedItems.length) {
      warningNotification('Tag modification complete. Not all tags could be modified')
    } else {
      errorNotification('No tags were modified')
    }

    setTagHistogram({})
    handleClose()
  }

  const listEditableTags = Object.entries(tagHistogram).map(([originalTag, count]) => ({
    originalTag,
    count,
  }))

  return listEditableTags.length > 0 ? (
    <DialogMultiTagEditSelectionTable
      listEditableTags={listEditableTags}
      executeEditTags={editTags}
    />
  ) : (
    <Paper style={{margin: 10, padding: 20}} level={1}>
      <Typography>No tags were detected for this selection</Typography>
    </Paper>
  )
}

DialogMultiTagEdit.propTypes = {
  /** List of selected items to carry out operations upon */
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Subset of selected items which cannot be tagged */
  untaggableIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Closes dialog */
  handleClose: PropTypes.func.isRequired,
}

export default DialogMultiTagEdit
