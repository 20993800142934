import React, {useContext, useEffect, useRef} from 'react'

import DirectoryLayout from '../layouts/DirectoryLayout'
import CommonLayout from '../layouts/CommonLayout'
import {imagineServiceContext} from '../context/imagineServiceContext'
import {telemetry} from '../utilities/telemetry'

import useDirectoryPageState from '../hooks/useDirectoryPageState'
import {asyncThrottleIterator} from '../utilities/async'
import type {StacExtension} from '@bayer-int/imagine-sdk-browser'
import {DirectoryPageCacheData, DisplayElement} from './DirectoryPageProjects'

const DIRECTORY_PAGE_EXTENSIONS_CACHE_KEY = `DIRECTORY_PAGE_EXTENSIONS_CACHE_KEY`

function safeParseInt(n: string, defaultValue: number) {
  try {
    return parseInt(n, 10)
  } catch (e) {
    return defaultValue
  }
}

function semanticVersionIsGreater(version1, version2): boolean {
  const v1 = version1.split('.').map((v: string) => safeParseInt(v, 0))
  const v2 = version2.split('.').map((v: string) => safeParseInt(v, 0))
  if (v1?.[0] !== v2?.[0]) {
    return v1?.[0] > v2?.[0]
  }
  if (v1?.[1] !== v2?.[1]) {
    return v1?.[1] > v2?.[1]
  }
  if (v1?.[2] !== v2?.[2]) {
    return v1?.[2] > v2?.[2]
  }
  return false
}

function extensionToDisplayElement(extension: StacExtension): DisplayElement {
  return {
    id: `${extension.id}:${extension.version}`,
    name: extension.id,
    title: extension.title,
    version: extension.version,
    entitlements: [],
    description: extension.description,
    thumbnail: null,
    count: 1,
  }
}

function DirectoryPageExtensions() {
  const {
    loading: globalLoading,
    error: globalLoadingError,
    imagineSdk,
  } = useContext(imagineServiceContext)
  const {
    state,
    dispatch,
    setError,
    setData,
    incrementNumberItemsPopulated,
    itemPopulatingComplete,
  } = useDirectoryPageState()
  const mounted = useRef(false)
  const abortController = useRef(new AbortController())
  const {filter} = state

  async function onAddData(extension: StacExtension) {
    const displayElement = extensionToDisplayElement(extension)
    const allElements = (state.data ?? []).concat(displayElement)
    setData(allElements)
  }

  function loadCache(): boolean {
    const cachedDataStr = window.localStorage.getItem(DIRECTORY_PAGE_EXTENSIONS_CACHE_KEY)
    if (!cachedDataStr) {
      return
    }
    let cachedData: DirectoryPageCacheData = JSON.parse(cachedDataStr)
    const hasCache = !!cachedData.displayElements?.length
    if (hasCache) {
      dispatch({
        type: 'set-max-count',
        maxCount: cachedData.cnt,
      })
      if (mounted.current) {
        setData(cachedData.displayElements ?? [])
        incrementNumberItemsPopulated()
      }
    }
    return hasCache
  }

  function handleExtLoadError(e: Error) {
    const additionalErrorDetails = 'There was an error retrieving extensions.'
    telemetry.error(e, {details: additionalErrorDetails})
    if (mounted.current) {
      setError(e.message)
    }
  }

  function updateMaxCount(cnt: number) {
    if (mounted.current) {
      dispatch({
        type: 'set-max-count',
        maxCount: cnt,
      })
    }
  }

  async function loadExtensions(
    onExtensionData: (ext: StacExtension) => DisplayElement
  ): Promise<DisplayElement[]> {
    const displayElementsHash = {}
    const iterator = imagineSdk.fetchStacExtensions()

    async function listenerWrapper(ext: StacExtension): Promise<DisplayElement> {
      const displayElement = onExtensionData(ext)
      displayElementsHash[displayElement.name] = displayElement
      return displayElement
    }

    await asyncThrottleIterator(iterator, listenerWrapper, {
      limit: 20,
      abortController: abortController.current,
      checkDelay: 20,
    })
    return Object.values(displayElementsHash)
  }

  function createExtensionDataListener(hasCache: boolean): (ext: StacExtension) => DisplayElement {
    const displayElementHash = {}
    let bufferCnt = 0
    return function onExtensionData(ext: StacExtension): DisplayElement {
      bufferCnt += 1
      let displayElement = extensionToDisplayElement(ext)
      const existingExt = displayElementHash[displayElement.name]
      if (existingExt) {
        if (semanticVersionIsGreater(existingExt.version, displayElement.version)) {
          displayElement = existingExt
        }
        displayElement.count = existingExt.count + 1
      }
      displayElementHash[displayElement.name] = displayElement

      if (bufferCnt > 10 && !hasCache) {
        const elements = Object.values(displayElementHash)
        setData(elements)
        updateMaxCount(elements.length)
        bufferCnt = 0
      }

      return displayElement
    }
  }

  function onCompleteLoad(displayElements: DisplayElement[]) {
    setData(displayElements)
    itemPopulatingComplete()
    window.localStorage.setItem(
      DIRECTORY_PAGE_EXTENSIONS_CACHE_KEY,
      JSON.stringify({
        cnt: displayElements.length,
        displayElements,
      })
    )
  }

  useEffect(function () {
    mounted.current = true
    // Make clear that the component is no longer mounted via ref on unmount
    return () => {
      mounted.current = false
      abortController.current.abort()
    }
  }, [])

  useEffect(() => {
    const loadData = async () => {
      let hasCache = false
      try {
        hasCache = loadCache()
      } catch (e) {
        // ignore
      }

      try {
        const listener = createExtensionDataListener(hasCache)
        const displayElements = await loadExtensions(listener)

        if (mounted.current) {
          onCompleteLoad(displayElements)
        }
      } catch (err) {
        handleExtLoadError(err)
        return
      }
    }

    if (!(globalLoading || globalLoadingError) && mounted.current) loadData()
  }, [globalLoading, globalLoadingError, mounted.current])

  const filteredContent = state.data?.filter(
    ({name, title}) =>
      title?.toLocaleLowerCase()?.includes(filter?.toLocaleLowerCase()) ||
      name?.toLocaleLowerCase()?.includes(filter?.toLocaleLowerCase())
  )

  return (
    <CommonLayout>
      <DirectoryLayout
        filteredContent={filteredContent}
        loading={state.loading}
        error={state.error || globalLoadingError}
        populating={state.itemsPopulated < state.maxCount}
        numPossibleElements={state.data?.length}
        maxCount={state.maxCount}
        contentType="extensions"
        onAddData={onAddData}
      />
    </CommonLayout>
  )
}

export default DirectoryPageExtensions
