import type {Album} from '@bayer-int/imagine-sdk-browser'
import React, {createContext, ReactNode, useState} from 'react'

export type AlbumState = {
  albums: Album[] | null
  excludeRoleState: [string[], React.Dispatch<React.SetStateAction<string[]>>] | null
  attributeState: [string[], React.Dispatch<React.SetStateAction<string[]>>] | null
  attributeListPage: [number, React.Dispatch<React.SetStateAction<number>>] | null
}

/**
 * Provides a list of items. Used in Gallery views and item view.
 *
 */
const initialState: AlbumState = {
  albums: null,
  excludeRoleState: null,
  attributeState: null,
  attributeListPage: null,
}

const albumExportContext = createContext<AlbumState>(initialState)
const {Provider} = albumExportContext

/**
 * Album Export Store
 * Provides a store to hone down on prop drilling for updating and deleting
 * */
function AlbumExportProvider({children}: {children: ReactNode}) {
  const excludeRoleState = useState<string[]>([])
  const attributeState = useState<string[]>([])
  const attributeListPage = useState(0)

  return (
    <Provider
      value={{
        albums: null,
        excludeRoleState,
        attributeState,
        attributeListPage,
      }}
    >
      {children}
    </Provider>
  )
}

export {albumExportContext, AlbumExportProvider}
