import {getEnvironment, isLocal} from './serviceBindings'
import {datadogRum} from '@datadog/browser-rum'
import {datadogLogs} from '@datadog/browser-logs'
import {JSONObject} from './json'
import type {SessionInfo} from '@bayer-int/imagine-sdk-browser'
import {version} from '../../package.json'

export function setupTelemetry() {
  try {
    const env = getEnvironment()
    datadogRum.init({
      version,
      applicationId: '2dd3cdbc-c74d-43a7-86c6-800eb8d8e453',
      clientToken: 'pub7a4037ff6cbd1db36c00043ce5e94e89',
      site: 'datadoghq.com',
      service: 'imagine-ui',
      env,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      traceSampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        /https:\/\/.*\.bayer\.com/,
        /https:\/\/.*\.phoenix-tools(-np)?\.io/,
        /https:\/\/.*\.agro\.services/,
        /https:\/\/.*\.velocity(-np)?\.ag/,
      ],
    })
    datadogLogs.init({
      version,
      clientToken: 'pub7a4037ff6cbd1db36c00043ce5e94e89',
      site: 'datadoghq.com',
      service: 'imagine-ui',
      env,
      sessionSampleRate: 100,
    })
  } catch (err) {
    console.error(err)
  }
}

export function setTelemetryUser(sessionInfo: SessionInfo) {
  const user = {
    id: sessionInfo.id,
    name: sessionInfo.name,
    email: sessionInfo.email,
  }
  datadogRum.setUser(user)
  datadogLogs.setUser(user)
}

export type TelemetryOptions = {
  telemetry: ITelemetry
  level?: 'error' | 'warning' | 'info' | 'debug'
}

class DatadogTelemetry implements ITelemetry {
  debug(msg: string, options?: TelemetryLogOptions) {
    datadogLogs.logger.debug(msg, options)
  }

  info(msg: string, options?: TelemetryLogOptions) {
    datadogLogs.logger.info(msg, options)
  }

  warn(msg: string, options?: TelemetryLogOptions) {
    datadogLogs.logger.warn(msg, options)
  }

  error(err: Error, options?: TelemetryLogOptions) {
    datadogLogs.logger.error(err.message, options, err)
  }

  sessionId(): string | undefined {
    return datadogRum.getInternalContext()?.session_id
  }
}

export type TelemetryLogOptions = JSONObject

type ITelemetry = {
  debug(msg: string, options?: TelemetryLogOptions): void
  info(msg: string, options?: TelemetryLogOptions): void
  warn(msg: string, options?: TelemetryLogOptions): void
  error(err: Error, options?: TelemetryLogOptions): void
  sessionId(): string | undefined
}

class Telemetry implements ITelemetry {
  telemetry: ITelemetry
  options: TelemetryOptions

  constructor(options: TelemetryOptions) {
    this.options = {level: 'info', ...options}
    this.telemetry = options.telemetry
  }

  configure(options: Partial<TelemetryOptions>) {
    this.options = {...this.options, ...options}
  }

  debug(msg: any, options?: TelemetryLogOptions) {
    console.debug(msg, options)
    if (isLocal) {
      return
    }
    try {
      const text = typeof msg === 'string' ? msg : JSON.stringify(msg, null, 2)
      this.telemetry.debug(text, options)
    } catch (err) {
      console.error(err)
    }
  }

  info(msg: string, options?: TelemetryLogOptions) {
    console.info(msg, options)
    if (isLocal) {
      return
    }
    try {
      this.telemetry.info(msg, options)
    } catch (err) {
      console.error(err)
    }
  }

  warn(msg: string, options?: TelemetryLogOptions) {
    console.warn(msg, options)
    if (isLocal) {
      return
    }
    try {
      this.telemetry.warn(msg, options)
    } catch (err) {
      console.error(err)
    }
  }

  error(err: Error, options?: TelemetryLogOptions) {
    console.error(err, options)
    if (isLocal) {
      return
    }
    try {
      this.telemetry.error(err, options)
    } catch (err) {
      console.error(err)
    }
  }

  sessionId(): string | undefined {
    return this.telemetry.sessionId()
  }
}

export const telemetry: ITelemetry = new Telemetry({
  telemetry: new DatadogTelemetry(),
})
