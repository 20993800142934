import React, {useContext, useEffect, useState} from 'react'
import {Autocomplete, Chip} from '@mui/material'
import {imagineServiceContext} from '../context/imagineServiceContext'
import {telemetry} from '../utilities/telemetry'
import useToastNotification from '../hooks/useToastNotification'
import {useFormikContext} from 'formik'
import type {CreateStacExtensionOptions} from '@bayer-int/imagine-sdk-browser'
import {CreateCollectionFields, ExtOption, requiredExtensions, StyledTextField} from './SelfService'
import {FormikErrorMessage} from './FormikErrorMessage'
import {AutocompleteOption} from './AutocompleteOption'

export const STAC_EXTENSIONS_QUERY = 'STAC_EXTENSIONS_QUERY'

export function ExtensionSelectField({
  onClose,
  recentExtensions,
}: {
  onClose: () => void
  recentExtensions: CreateStacExtensionOptions[]
}) {
  const {values, handleBlur, setFieldValue, initialValues} =
    useFormikContext<CreateCollectionFields>()
  const {errorNotification} = useToastNotification()
  const [extensions, setExtensions] = useState<ExtOption[]>([])
  const {retrieveStacExtensions, loading: contextLoading} = useContext(imagineServiceContext)
  const [loading, setLoading] = useState(true)

  function loadExtensionsCache() {
    const cache = window.localStorage.getItem(STAC_EXTENSIONS_QUERY)
    let exts: ExtOption[]
    if (cache) {
      exts = JSON.parse(cache)
      setFieldValue(
        'extensions',
        exts.filter(
          ext =>
            requiredExtensions.includes(ext.value) ||
            !!(initialValues.extensions ?? []).find(e => e.value === ext.value)
        )
      )
      setExtensions(exts)
    }
  }

  async function loadExtensions() {
    const extsResponse = await retrieveStacExtensions()
    const exts = extsResponse
      .filter(ext => !!ext)
      .map(ext => ({
        label: ext.title,
        value: ext.id,
        description: ext.description,
        version: ext.version,
        disabled: requiredExtensions.includes(ext.id),
      }))
      .sort((a, b) => (a.label ?? '')?.localeCompare(b.label ?? ''))
    window.localStorage.setItem(STAC_EXTENSIONS_QUERY, JSON.stringify(exts))

    setFieldValue(
      'extensions',
      exts.filter(
        ext =>
          requiredExtensions.includes(ext.value) ||
          !!(initialValues.extensions ?? []).find(e => e.value === ext.value)
      )
    )
    setExtensions(exts)
  }

  useEffect(() => {
    try {
      loadExtensionsCache()
    } catch (err) {
      const details = 'failed to load stac extension cache'
      telemetry.error(err, {details})
    } finally {
      setLoading(false)
    }
  }, [initialValues])

  useEffect(() => {
    if (!contextLoading) {
      loadExtensions()
        .catch(err => {
          const details = 'failed to load stac extensions'
          telemetry.error(err, {details})
          errorNotification(details)
          onClose()
        })
        .finally(() => setLoading(false))
    }
  }, [contextLoading, recentExtensions])

  return (
    <>
      <Autocomplete
        multiple
        disableClearable
        options={extensions}
        loading={loading || contextLoading}
        value={values.extensions}
        renderInput={params => <StyledTextField {...params} name="extensions" label="Extensions" />}
        onBlur={handleBlur}
        onChange={(_, value) => {
          setFieldValue('extensions', value)
        }}
        renderTags={(tagValue, getTagProps) => (
          <>
            {tagValue.map((option, index) => {
              const props: any = {}
              if (option.disabled) {
                props.onDelete = null
              }
              return (
                <Chip
                  label={option.label}
                  {...getTagProps({index})}
                  disabled={option.disabled}
                  {...props}
                  key={`${option.value}:${option.version}`}
                />
              )
            })}
          </>
        )}
        renderOption={(props, option: ExtOption) => (
          <AutocompleteOption
            name={option.label}
            description={option.label ? `(v${option.version}) ${option.description}` : ''}
            {...props}
            key={`${option.value}:${option.version}`}
          />
        )}
        getOptionDisabled={option =>
          option.disabled || values.extensions.findIndex(ext => ext.value === option.value) !== -1
        }
        getOptionLabel={option => option.label ?? option.value}
      />
      <FormikErrorMessage name="extensions" />
    </>
  )
}
