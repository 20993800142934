import React, {ReactNode, useContext, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import styled from 'styled-components'

import {
  setUploadMetadataSchema,
  updateMetadataForm,
  setAvailableWorkflowStages,
  indicateWorkflowFailure,
} from '../actions/imageUploaderAction'
import useToastNotification from '../hooks/useToastNotification'
import {imagineServiceContext} from '../context/imagineServiceContext'

import UploaderImagineMetadataInput from './UploaderImagineMetadataInput'
import LoadingSpinnerInline from './LoadingSpinnerInline'
import {telemetry} from '../utilities/telemetry'
import {StoreState} from '../reducers/appStore'
import {SchemaField, UploaderMetadataContent} from '../reducers/imageUploaderReducer'
import type {PropertyValidationFailure} from '@bayer-int/imagine-sdk-browser'
import {Box, FormControlLabel, Paper, Switch, Typography} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

const MetadataFormSection = styled.section`
  max-height: 583px;

  overflow-y: scroll;
  overflow-x: hidden;

  form {
    display: grid;
    grid-auto-rows: 80px;
    grid-row-gap: 2px;

    div {
      justify-self: center;
    }

    .two-rows {
      grid-row: span 2;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      width: 100%;
    }
  }
`

const Container = styled.div`
  width: 80%;
  margin: 80px auto 0 auto;
`

function UploaderImagineStageMetadataForm() {
  const {imagineSdk} = useContext(imagineServiceContext)
  const [formContentLoading, setFormContentLoading] = useState(true)
  const [formWarnings, setformWarnings] = useState({})
  const dispatch = useDispatch()
  const {infoNotification, errorNotification} = useToastNotification()
  const [viewRequiredOnly, setViewRequiredOnly] = useState(false)

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
    setViewRequiredOnly(newValue)
  }

  const selectedCollection = useSelector<StoreState, string>(
    state => state.imageUploader.selectedCollection
  )
  const selectedProject = useSelector<StoreState, string>(
    state => state.imageUploader.selectedProject
  )
  const formContent = useSelector<StoreState, SchemaField[]>(
    state => state.imageUploader.metadataSchema
  )
  const formValues = useSelector<StoreState, UploaderMetadataContent>(
    state => state.imageUploader.metadataContent
  )
  const formErrors = useSelector<StoreState, PropertyValidationFailure[]>(
    state => state.imageUploader.metadataValidationResults
  )
  const setWorkflowStages = (stages: number[]) => dispatch(setAvailableWorkflowStages(stages))
  const setDialogError = (message: string) => {
    dispatch(indicateWorkflowFailure(message))
    setWorkflowStages([4])
  }
  const updateFormSchema = (schema: unknown[]) => dispatch(setUploadMetadataSchema(schema))
  const updateFormValues = (data: UploaderMetadataContent) => {
    dispatch(
      updateMetadataForm({
        ...formValues,
        properties: {
          ...formValues.properties,
          ...data,
          'project:name': selectedProject,
        },
      })
    )
  }

  // Restrict workflow stages if user edits the form
  // This also disables the upload button if the form was already successfully validated
  useEffect(() => {
    setWorkflowStages([1, 2])
  }, [formValues])

  // Populates the type of form elements that will appear on the form
  useEffect(() => {
    if (!selectedCollection || !imagineSdk) {
      return
    }

    const populateCollectionSchema = async () => {
      setFormContentLoading(true)
      const collection = await imagineSdk.fetchCollection({id: selectedCollection})
      const schema = await collection.itemSchema()

      const now = new Date().toISOString()
      const objectProperties = schema.jsonSchema.properties.properties
      const {properties, required} = objectProperties
      const formData = Object.entries(properties).map(([key, attributes]) => {
        const defaultValue = key === 'datetime' ? now : ''
        return {
          attribute: key,
          value: defaultValue,
          required: required.includes(key),
          ...attributes,
        }
      })
      updateFormValues({datetime: now})
      updateFormSchema(formData)
      setFormContentLoading(false)
    }

    populateCollectionSchema().catch(err => {
      const details = 'Error populating collection schema form.'
      telemetry.error(err, {details, type: 'upload', subtype: 'ImagineServiceUploader'})
      setDialogError(details)
      setFormContentLoading(false)
    })
  }, [selectedCollection])

  useEffect(() => {
    try {
      if (formErrors) {
        const isMissingGeometry =
          formErrors.filter(
            e =>
              e.message ===
              'A geometry was not provided. By default, a Point with coordinates [0, 0] will be used.'
          ).length > 0

        if (isMissingGeometry) {
          dispatch(
            updateMetadataForm({
              ...formValues,
              geometry: {
                type: 'Point',
                coordinates: [0, 0],
              },
            })
          )
        }
      }
    } catch (err) {
      const details =
        'Internal error occurred while checking for missing geometry. Please contact Location360 support.'
      telemetry.error(err, {details, type: 'upload', subtype: 'ImagineServiceUploader'})
      setDialogError(details)
    }
  }, [formErrors])

  useEffect(() => {
    const errorsPresent = formErrors?.length > 0

    // Prevent toast notification on form initial load
    if (!formContentLoading) {
      if (errorsPresent) {
        errorNotification('Errors found. Please fix errors before proceeding.', {timeout: 1000})
      } else {
        infoNotification('No errors found. Uploading is enabled.')
      }
    }
  }, [formErrors])

  // Map the form values for warnings
  useEffect(() => {
    const formErrorObject = {}
    if (formErrors?.length > 0) {
      formErrors.forEach(({message, violation}) => {
        const propertyName = violation.split('"')[1]
        formErrorObject[propertyName] = {message}
      })
    }
    setformWarnings(formErrorObject)
  }, [formErrors])

  // Base contents:
  // 1. A image (click and drag or plus sign)
  // 2. A preview of said image if possible without requiring upload

  // Seperate out the fields so the form can be organised effectively
  // Render objects and arrays later as they are less compact
  const restrictedFieldTypes = ['object', 'array', 'string']

  // Prevents use of the field geohash in any form.
  const restrictedTextFieldRe = /geohash/

  const acceptedField = f => !restrictedFieldTypes.includes(f.type)
  const acceptedArrayStructure = f => f.type === 'array' && f.items.type === 'string'
  const acceptedTextField = f => f.type === 'string' && !restrictedTextFieldRe.test(f.attribute)

  const acceptedFieldFilter = f =>
    acceptedField(f) || acceptedTextField(f) || acceptedArrayStructure(f)

  const fieldGroups = formContent
    .filter(acceptedFieldFilter)
    .filter(field => field.attribute !== 'project:name')
    .filter(field => (viewRequiredOnly ? field.required : true))
    .reduce((groups, field) => {
      const parts = field.attribute.split(':')
      const prefix = parts.length > 1 ? parts[0] : 'General'

      if (!groups[prefix]) {
        groups[prefix] = []
      }
      groups[prefix].push(field)
      return groups
    }, {})

  const sortedGroups = Object.keys(fieldGroups).sort((a, b) => {
    if (a === 'General') return -1
    if (b === 'General') return 1
    return a.localeCompare(b)
  })

  if (formContentLoading)
    return (
      <MetadataFormSection>
        <Container>
          <LoadingSpinnerInline message="Loading metadata form..." />
        </Container>
      </MetadataFormSection>
    )

  return (
    <MetadataFormSection>
      <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
        <FormControlLabel
          control={
            <Switch
              checked={viewRequiredOnly}
              onChange={handleToggleChange}
              color="primary"
              inputProps={{'aria-label': 'toggle to view only required fields'}}
            />
          }
          label="View required only"
        />
      </Box>
      <form noValidate autoComplete="off" style={{minHeight: '500px'}}>
        <Accordion
          items={sortedGroups.map(groupName => {
            const required = fieldGroups[groupName].some(field => field.required)
            const warning = fieldGroups[groupName].some(field => !!formWarnings[field.attribute])
            return {
              title: groupName,
              content: (
                <>
                  {fieldGroups[groupName].map((field, index) => (
                    <UploaderImagineMetadataInput
                      key={`metadata-prop-input-${field.attribute}-${index}`}
                      field={field}
                      value={formValues.properties[field.attribute]}
                      onChange={updateFormValues}
                      warning={formWarnings[field.attribute]}
                    />
                  ))}
                </>
              ),
              required,
              warning,
            }
          })}
        />
      </form>
    </MetadataFormSection>
  )
}

const AccordionItem = ({title, required, warning, children}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box mb={1} border="1px solid lightgrey" borderRadius="4px">
      <Paper
        elevation={2}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
          '&:hover': {bgcolor: 'rgba(0, 0, 0, 0.04)'},
          width: '100%',
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Typography color={warning ? 'red' : undefined}>
          {title} {required ? '*' : ''}
        </Typography>
        <Box>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>
      </Paper>
      {isOpen && (
        <Box display="flex" flexDirection="column" gap={1} width="100%" p={2}>
          {children}
        </Box>
      )}
    </Box>
  )
}

const Accordion = ({
  items,
}: {
  items: {title: string; content: ReactNode; required: boolean; warning: boolean}[]
}) => (
  <Box display="flex" flexDirection="column" width="100%" p={2}>
    {items.map((item, index) => (
      <AccordionItem key={index} title={item.title} required={item.required} warning={item.warning}>
        {item.content}
      </AccordionItem>
    ))}
  </Box>
)

export default UploaderImagineStageMetadataForm
