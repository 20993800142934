import React from 'react'
import PropTypes from 'prop-types'
import {TextField} from '@mui/material'

export const orange = `#FFA500`
export const red = `#b00020` // current value for var(--mdc-theme-error)

function UploaderImagineMetadataFieldText({fieldName, value, error, onChange, warning, required}) {
  return (
    <TextField
      fullWidth
      id={`textfield-${fieldName}`}
      label={fieldName}
      value={value}
      onChange={onChange}
      error={!!error}
      helperText={warning ? `(warning) ${error}` : error}
      variant="standard"
      sx={{color: red}}
      required={required}
    />
  )
}

UploaderImagineMetadataFieldText.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  warning: PropTypes.bool,
}

UploaderImagineMetadataFieldText.defaultProps = {
  warning: false,
  error: '',
  value: '',
}

export default UploaderImagineMetadataFieldText
