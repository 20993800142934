import React, {useEffect, useContext, useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Button, Paper, Typography} from '@mui/material'
import {
  Info as InfoIcon,
  PushPinOutlined as PushPinOutlinedIcon,
  PushPin as PushPinIcon,
} from '@mui/icons-material'
import {imagineServiceContext} from '../context/imagineServiceContext'

import useCollectionPin from '../hooks/useCollectionPin'
import groupingItemCountCopy from '../helpers/groupingItemCountCopy'
import ImagineCollectionTimeline from './ImagineCollectionHistogram/ImagineCollectionTimeline'
import GallerySummaryCollectionEditTitleButton from './GallerySummaryCollectionEditTitleButton'
import GallerySummaryCollectionEditDescButton from './GallerySummaryCollectionEditDescButton'
import GallerySummaryCollectionDeleteButton from './GallerySummaryCollectionDeleteButton'

const SummaryContainer = styled(Paper)`
  padding: 5px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-column-gap: 15px;

  .infoIcon {
    display: flex;
    color: var(--mdc-theme-primary);
    justify-self: center;
    align-self: center;
  }

  .editIconWrapper {
    marginleft: 5px;
    display: inline-flex;
  }

  .editIcon {
    width: 20px;
  }

  .titleBlock {
    display: flex;
    justify-content: space-between;
  }

  .textWrapper {
    display: inline-flex;
    align-items: center;
  }

  .description {
    color: ${props => props.theme?.palette?.subduedText};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .action {
    grid-column: 2/ 3;
    justify-content: flex-end;
    display: flex;
  }

  button {
    width: 280px;
  }

  p {
    margin-right: 5px;
  }
`

const TimelineContainer = styled(Paper)`
  padding: 5px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-column-gap: 15px;

  .icon {
    display: flex;
    color: var(--mdc-theme-primary);
    justify-self: center;
    align-self: flex-start;
  }

  .toggleTimeline {
    color: var(--mdc-theme-primary);
    cursor: pointer;
  }
`

/**
 * The summary of results for a given collection
 *
 */
function GallerySummaryCollection(props) {
  const {description, numberItemsInCollection, name, openSchemaDialog, collection} = props
  const {imagineSdk} = useContext(imagineServiceContext)
  const [lastUpdatedDate, setLastUpdatedDate] = useState('')
  const [showTimeline, setShowTimeline] = useState(false)
  const [timelineData, setTimelineData] = useState([])
  const [reasonTimelineLimitedData, setReasonTimelineLimitedData] = useState('')
  const [isPinned, setIsPinned] = useState(false)
  const [pinnedCollections, setPinnedCollections] = useState([])
  const [hasDeletePermission, setHasDeletePermission] = useState(false)
  const [hasPublishPermission, setHasPublishPermission] = useState(false)

  const {getPinnedCollections, addToPinnedCollections, removeFromPinnedCollections} =
    useCollectionPin()

  useEffect(() => {
    getPinnedCollections().then(pinnedCollections => {
      setIsPinned(pinnedCollections.includes(name))
      setPinnedCollections(pinnedCollections)
    })
  }, [])

  useEffect(() => {
    async function getEntitlements() {
      const entitlements = await collection.entitlements()
      if (entitlements.currentUserHasEntitlement('DELETE')) {
        setHasDeletePermission(true)
      }
      if (entitlements.currentUserHasEntitlement('PUBLISH')) {
        setHasPublishPermission(true)
      }
    }

    getEntitlements()
  }, [collection])

  const handlePinButton = () => {
    if (isPinned) {
      removeFromPinnedCollections(pinnedCollections, name)
      setIsPinned(false)
    } else {
      addToPinnedCollections(pinnedCollections, name)
      setIsPinned(true)
    }
  }

  // Retrieves oldest and newest collection information to build timeline
  // The newest and oldest dates for a collection are obtained by making calls directly to API (bypassing SDK)
  useEffect(() => {
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })

    const getNewestUpdatedDate = async collection => {
      const itemsIterator = await collection.fetchItems({
        limit: 1,
        sortBy: [
          {
            direction: 'desc',
            field: 'properties.datetime',
          },
        ],
      })

      for await (const item of itemsIterator) {
        let newestDate = new Date(item.properties.datetime)
        // If date of newest item is in the future or does not exist, set to today's date
        if (!(newestDate instanceof Date && isFinite(newestDate)) || newestDate > Date.now()) {
          newestDate = new Date(Date.now())
        } else {
          // Only set lastUpdatedDate if date of newest item is in the past AND exists
          setLastUpdatedDate(dateFormatter.format(newestDate))
        }
        return newestDate
      }
    }

    getNewestUpdatedDate(collection)
  }, [])

  return (
    <>
      <SummaryContainer elevation={0}>
        <div className="infoIcon">
          <InfoIcon />
        </div>
        <div>
          <div className="titleBlock">
            <div className="textWrapper">
              <Typography variant="body1">
                {groupingItemCountCopy({
                  grouping: 'collection',
                  name,
                  count: numberItemsInCollection,
                })}
              </Typography>
              {hasPublishPermission && (
                <GallerySummaryCollectionEditTitleButton name={name} collection={collection} />
              )}
            </div>
            {hasDeletePermission && (
              <GallerySummaryCollectionDeleteButton name={name} collection={collection} />
            )}
          </div>

          <div className="description">
            <div className="textWrapper">
              <Typography variant="body2">{description}</Typography>
              {hasPublishPermission && (
                <GallerySummaryCollectionEditDescButton
                  description={description}
                  collection={collection}
                />
              )}
            </div>
            <Button
              color="primary"
              onClick={handlePinButton}
              endIcon={isPinned ? <PushPinIcon /> : <PushPinOutlinedIcon />}
            >
              {isPinned ? 'Unpin Collection' : 'Pin Collection'}
            </Button>
          </div>
          <div>
            <div className="action">
              <Button color="primary" onClick={openSchemaDialog}>
                View and Select Schema
              </Button>
            </div>
          </div>
        </div>
      </SummaryContainer>
      <TimelineContainer elevation={0}>
        <div className="icon">
        </div>
        <div>
          <Typography variant="body5">
            <span className="toggleTimeline" onClick={() => setShowTimeline(!showTimeline)}>
              {!showTimeline ? 'Show timeline ' : 'Hide timeline '}
            </span>
            for "{name}". {lastUpdatedDate && `Last updated on ${lastUpdatedDate}`}
          </Typography>
          {showTimeline && (
            <ImagineCollectionTimeline
              collection={collection}
              message={reasonTimelineLimitedData}
            />
          )}
        </div>
      </TimelineContainer>
    </>
  )
}

GallerySummaryCollection.propTypes = {
  /** The description of the collection */
  description: PropTypes.string,
  /** The name of this collection */
  name: PropTypes.string.isRequired,
  /** Lists the number of items for this collection */
  numberItemsInCollection: PropTypes.number.isRequired,
  /** Opens the schema dialog */
  openSchemaDialog: PropTypes.func.isRequired,
  /** The collection instance */
  collection: PropTypes.object.isRequired,
}

GallerySummaryCollection.defaultProps = {
  description: '',
}

export default GallerySummaryCollection
