import React from 'react'
import App from './App'
import {navbarInstalled} from './utilities/initializeProfile'
import {createRoot} from 'react-dom/client'

import './style.css'
import {setupInterceptor} from './utilities/fetchInterceptor'
import {setupCache} from './utilities/cache'
import {setTelemetryUser, setupTelemetry, telemetry} from './utilities/telemetry'
import {sdk} from './context/imagineServiceContext'

setupInterceptor()
setupCache()
setupTelemetry()

const container = document.querySelector('.contents')
const root = createRoot(container)

if (!process.env.TESTING) {
  navbarInstalled
    .then(() => {
      root.render(<App />)
    })
    .catch(e => {
      telemetry.error(e, {details: 'Initial render failed'})
    })
}

const setTelemetryUserInterval = setInterval(() => {
  if (sdk) {
    clearInterval(setTelemetryUserInterval)
    sdk
      .sessionInfo()
      .then(user => {
        setTelemetryUser(user)
      })
      .catch(err => telemetry.error(err, {details: 'Failed to set user for telemetry.'}))
  }
}, 100)
