export interface H3Data {
  h3index: string
  count: number
}

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export function gradientColorsGenerator(startColor, endColor, steps) {
  const startRGB = hexToRgb(startColor)
  const endRGB = hexToRgb(endColor)
  const colors = []

  for (let i = 0; i < steps; i++) {
    const r = Math.round(startRGB.r + ((endRGB.r - startRGB.r) * i) / (steps - 1))
    const g = Math.round(startRGB.g + ((endRGB.g - startRGB.g) * i) / (steps - 1))
    const b = Math.round(startRGB.b + ((endRGB.b - startRGB.b) * i) / (steps - 1))
    colors.push(`rgb(${r}, ${g}, ${b})`)
  }
  return colors
}

export const webMToH3ResolutionMapping = {
  1: 1,
  2: 1,
  3: 2,
  4: 2,
  5: 3,
  6: 3,
  7: 4,
  8: 4,
  9: 5,
  10: 5,
  11: 6,
  12: 6,
  13: 7,
  14: 8,
  15: 9,
  16: 10,
  17: 11,
  18: 12,
  19: 13,
  20: 14,
  21: 15,
  22: 15,
  23: 15,
  24: 15,
}
