import {useCallback, useEffect, useState} from 'react'
import {
  cqlLanguage,
  cqlFunctionCompletionSource,
  cqlIdentifierCompletionSource,
} from 'codemirror-lang-cql'
import {NotFoundException, useImagineSdk} from '../../context/imagineServiceContext'
import {telemetry} from '../../utilities/telemetry'
import {cqlCharacterLiteralCompletionSource} from './autocomplete'
import {parseSearchContext} from './cqlExpressionVisitor'
import {ViewUpdate} from '@uiw/react-codemirror'
import {sasDptUavAnalytics} from '../staticSchemas'

const defaultIdentifierCompletions = [
  'id',
  'collection',
  'collections',
  'geometry',
  'properties.exif:data',
  'properties.file:size',
  'properties.file:checksum',
  'properties.gh:geohash',
  'properties.project:name',
  'properties.storage:etag',
  'properties.storage:tier',
  'properties.storage:region',
  'properties.storage:platform',
  'properties.storage:requester_pays',
  'properties.tags:tags',
]

function useCql2Search({cql}: {cql: string}) {
  const {imagineSdk, retrieveCollections, retrieveProjects} = useImagineSdk()
  const [value, setValue] = useState(cql)
  const [collection, setCollection] = useState('')
  const [completeFor, setCompleteFor] = useState('')
  const [identifierCompletions, setIdentifierCompletions] = useState(defaultIdentifierCompletions)
  const [propertyCompletions, setPropertyCompletions] = useState([])

  const cqlFunctionCompletionExtension = cqlLanguage.data.of({
    autocomplete: cqlFunctionCompletionSource(['KEYWORDS']),
  })

  const cqlIdentifierCompletionExtension = cqlLanguage.data.of({
    autocomplete: cqlIdentifierCompletionSource(identifierCompletions),
  })

  const cqlCharacterLiteralCompletionExtension = cqlLanguage.data.of({
    autocomplete: cqlCharacterLiteralCompletionSource(propertyCompletions),
  })

  const onChange = useCallback((val: string, viewUpdate: ViewUpdate) => {
    setValue(val)
    try {
      const context = parseSearchContext(val, viewUpdate)
      if (context?.collections?.length === 1) {
        setCollection(context.collections[0])
      }
      if (context?.completeFor) {
        setCompleteFor(context.completeFor)
      }
    } catch (err) {
      console.error(err)
    }
  }, [])

  function parseProperties(obj: any, key: string): string[] {
    return Object.keys(obj).reduce((acc, name) => {
      const isObjectProperty = obj[name].type === 'object'
      const propName = `${key}${name}`
      if (isObjectProperty) {
        acc = acc.concat(parseProperties(obj[name].properties, propName + '.'))
      } else {
        acc.push(propName)
      }
      return acc
    }, [])
  }

  async function getProperties(name: string) {
    if (name === 'sas_dpt_uav_analytics') {
      return sasDptUavAnalytics.properties
    }
    const collection = await imagineSdk.fetchCollection({id: name})
    const itemSchema = await collection.itemSchema()
    const jsonSchema = itemSchema.jsonSchema as any
    return jsonSchema?.properties?.properties?.properties ?? {}
  }

  async function updateIdentifierKeywords(name: string) {
    const schemaProperties = await getProperties(name)
    const completions = parseProperties(schemaProperties, 'properties.')
    setIdentifierCompletions(completions.concat(defaultIdentifierCompletions))
  }

  async function updatePropertyCompletions(name: string) {
    if (['collection', 'collections'].includes(name)) {
      const collections = await retrieveCollections()
      setPropertyCompletions(collections.map(collection => collection.id))
    } else if (['project', 'properties.project:name'].includes(name)) {
      const projects = await retrieveProjects()
      setPropertyCompletions(projects.map(project => project.id))
    } else {
      setPropertyCompletions([])
    }
  }

  useEffect(() => {
    if (collection && imagineSdk) {
      updateIdentifierKeywords(collection).catch(err => {
        if (err instanceof NotFoundException) {
          return
        }
        telemetry.error(err, {
          details: `Failed to update identifier keywords for collection ${collection}`,
        })
      })
    } else {
      setIdentifierCompletions(defaultIdentifierCompletions)
    }
  }, [collection, imagineSdk])

  useEffect(() => {
    if (completeFor) {
      updatePropertyCompletions(completeFor).catch(err => {
        telemetry.error(err, {
          details: `Failed to update property completions for context: ${completeFor}`,
        })
      })
    } else {
      setPropertyCompletions([])
    }
  }, [completeFor, imagineSdk])

  return {
    cqlFunctionCompletionExtension,
    cqlIdentifierCompletionExtension,
    cqlCharacterLiteralCompletionExtension,
    value,
    onChange,
  }
}

export default useCql2Search
