import React, {useContext, useEffect, useState, createContext} from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material'

import {saveAs} from 'file-saver'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import styled from 'styled-components'

import DialogExportAlbumStepper from './DialogExportAlbumStepper'
import DialogExportAlbumAttributeSelection from './DialogExportAlbumAttributeSelection'
import DialogExportAlbumRoleExclusion from './DialogExportAlbumRoleExclusion'

import {AlbumState, albumExportContext} from '../context/albumExportContext'
import {Album, Item} from '@bayer-int/imagine-sdk-browser'
import {telemetry} from '../utilities/telemetry'
import CardGenericError from './CardGenericError'

const LoadingElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;

  p {
    text-decoration: auto;
    font-style: italic;
    color: #aaa;
    letter-spacing: 0.4px;
  }
`

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

type DialogExportAlbumOptions = {
  album: Album
  items: Item[]
  closeDialog: () => void
}

type SchemaAttributes = {name: string; type: string}

function DialogExportAlbum(props: DialogExportAlbumOptions) {
  const {album, closeDialog, items} = props
  const [loading, setLoading] = useState(true)
  const [downloading, setDownLoading] = useState(false)
  const [itemSchemas, setItemSchemas] = useState<SchemaAttributes[]>([])
  const [itemRoles, setItemRoles] = useState<string[]>([])
  const [dialogStage, setDialogStage] = useState<number>(0)
  const [err, setErr] = useState<Error | null>(null)

  const {
    attributeState: [selectedAttributes, setSelectedAttributes],
    excludeRoleState: [excludedRoles],
  } = useContext<AlbumState>(albumExportContext)

  useEffect(() => {
    let schemaAttributes: SchemaAttributes[] = []
    items.forEach(async (item, index) => {
      const collection = await item.collection()
      const schemas = await collection.itemStacExtensions()
      Object.entries(schemas).forEach(([_, value]) => {
        const schema = value.jsonSchema as any
        const properties = schema?.properties?.properties?.properties
        Object.keys(properties).forEach(k => {
          const isDuplicate = schemaAttributes.some(p => p.name === k)
          const type = properties[k]?.type
          const isSupportedType = !['object', 'array'].includes(type)
          if (!isDuplicate && isSupportedType) {
            schemaAttributes.push({name: k, type})
          }
        })
      })
      setItemSchemas(schemaAttributes)

      const assets = await item.fetchAssets()

      let cumulativeRoles: string[] = []
      for (const asset of assets) {
        const {roles} = asset
        if (roles) {
          cumulativeRoles = cumulativeRoles.concat(roles)
        }
      }

      if (index === items.length - 1) {
        setLoading(false)
        setItemRoles([...new Set(cumulativeRoles)])
      }
    })
  }, [])

  function DialogContentBody() {
    const {increment, reset} = useContext(CounterFnContext)

    async function downloadFile() {
      try {
        setDownLoading(true)
        const csvListing = album.generateManifestCsv({
          includeItemProperties: selectedAttributes,
          excludeAssetRoles: excludedRoles,
        })
        // album record counter is bugged
        //
        //const total = await csvListing.count()
        //setTotal(total)

        let lines = ''
        for await (const line of csvListing) {
          lines += '\n' + line
          increment()
        }

        saveAs(new Blob([lines], {type: 'text/plain;charset=utf-8'}), `export-${album.title}.csv`)
      } catch (err) {
        telemetry.error(err, {
          details: 'Error downloading album csv.',
          metadata: {
            album: album.id,
          },
        })
        setErr(err instanceof Error ? err : new Error(JSON.stringify(err)))
      } finally {
        setDownLoading(false)
        reset()
      }
    }

    if (loading)
      return (
        <LoadingElement>
          <CircularProgress />
          <p>Loading visible album attributes...</p>
        </LoadingElement>
      )

    if (downloading) {
      return (
        <LoadingElement>
          <CircularProgress />
          <p>
            Exporting album... <DownloadCnt />
          </p>
        </LoadingElement>
      )
    }

    return (
      <>
        {dialogStage === 0 && (
          <>
            <DialogContentText>Select the attributes you wish to export.</DialogContentText>
            <div style={{height: '490px'}}>
              <DialogExportAlbumAttributeSelection
                itemSchemas={itemSchemas}
                selectedAttributes={selectedAttributes}
                setSelectedAttributes={setSelectedAttributes}
              />
            </div>
          </>
        )}
        {dialogStage === 1 && (
          <>
            <DialogContentText>
              Select the asset roles you wish to exclude from the file.
            </DialogContentText>
            <div style={{height: '490px'}}>
              <DialogExportAlbumRoleExclusion possibleRoles={itemRoles} />
            </div>
          </>
        )}
        {dialogStage === 2 && (
          <>
            <div
              style={{
                height: '420px',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}
            >
              <Button
                color="primary"
                onClick={() => downloadFile()}
                variant="contained"
                startIcon={<CloudDownloadIcon />}
              >
                download csv
              </Button>
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-slide-title">Export Album</DialogTitle>
      <DialogContent sx={{minHeight: '500px', minWidth: '594px'}}>
        <div style={{padding: '24px'}}>
          <DialogExportAlbumStepper stage={dialogStage} />
        </div>
        {err ? (
          <CardGenericError title="Error exporting album" description={err.message} />
        ) : (
          <DownloadCountProvider>
            <DialogContentBody />
          </DownloadCountProvider>
        )}
      </DialogContent>
      <DialogActions>
        {(dialogStage === 1 || dialogStage === 2) && (
          <Button color="primary" onClick={() => setDialogStage(Number(dialogStage) - 1)}>
            Back
          </Button>
        )}
        {(dialogStage === 0 || dialogStage === 1) && (
          <Button
            color="primary"
            onClick={() => setDialogStage(Number(dialogStage) + 1)}
            disabled={
              (dialogStage === 0 && selectedAttributes.length === 0) ||
              (dialogStage === 1 && excludedRoles.length >= itemRoles.length)
            }
          >
            Next
          </Button>
        )}
        <Button onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

function DownloadCnt() {
  const {count} = useContext(CounterStateContext)

  // album record counter is bugged otherwise we could have added total here too
  return <>{count}</>
}

const CounterStateContext = createContext({count: 0, total: 0})
const CounterFnContext = createContext({
  increment: () => {},
  reset: () => {},
  setTotal: (cnt: number) => {},
})

const DownloadCountProvider = ({children}) => {
  const [count, setCount] = useState(0)
  const [total, setTotal] = useState(0)

  return (
    <CounterStateContext.Provider value={{count, total}}>
      <CounterFnContext.Provider
        value={{
          increment: () => setCount(prevCnt => prevCnt + 1),
          reset: () => setCount(0),
          setTotal: (cnt: number) => setTotal(cnt),
        }}
      >
        {children}
      </CounterFnContext.Provider>
    </CounterStateContext.Provider>
  )
}

export default DialogExportAlbum
