import React, {useEffect, useRef} from 'react'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import {Typography} from '@mui/material'
import {useSelector, useDispatch} from 'react-redux'

import {
  addAvailableWorkflowStages,
  setUploaderWorkflowStage,
  setAvailableWorkflowStages,
  setUploadImage,
  indicateWorkflowFailure,
} from '../actions/imageUploaderAction'
import DropArea from './styled/DropArea'
import DropInputLabel from './styled/DropInputLabel'
import {StoreState} from '../reducers/appStore'
import {telemetry} from '../utilities/telemetry'

// Prevents accidental closure of window
window.close = () => {
  console.error('window.close was called')
  debugger
}

/**
 * Image selection for Imagine Uploader
 */
function UploaderImagineStageFileSelector() {
  const selectedImage = useSelector<StoreState, File | null>(
    state => state.imageUploader.itemAssetFile
  )

  const dispatch = useDispatch()
  const setUploaderStage = (stage: number) => dispatch(setUploaderWorkflowStage(stage))
  const addWorkflowStages = (stages: number[]) => dispatch(addAvailableWorkflowStages(stages))
  const fileInputRef = useRef(null)
  const setWorkflowStages = (stages: number[]) => dispatch(setAvailableWorkflowStages(stages))
  const setStage = (stage: number) => dispatch(setUploaderWorkflowStage(stage))
  const setDialogError = (message: string) => {
    dispatch(indicateWorkflowFailure(message))
    setWorkflowStages([4])
    setStage(4)
  }

  const setImageFile = (image: File) => dispatch(setUploadImage(image))

  useEffect(() => {
    if (selectedImage) {
      addWorkflowStages([1])
      setUploaderStage(1)
    }
  }, [selectedImage])

  const selectFile = () => {
    try {
      const [file] = fileInputRef.current.files as File[]
      if (!file) {
        throw new Error('No file found from selected files.')
      }
      telemetry.info('File selected for upload.', {
        type: 'upload',
        subtype: 'ImagineServiceUploader',
        metadata: {
          file: {
            name: file.name,
            size: file.size,
            lastModified: file.lastModified,
          },
        },
      })
      setImageFile(file)
    } catch (err) {
      const details = 'Error occurred while selecting file.'
      telemetry.error(err, {details, type: 'upload', subtype: 'ImagineServiceUploader'})
      setDialogError(details)
    }
  }

  return (
    <DropArea>
      <input type="file" name="file" id="file" onChange={selectFile} ref={fileInputRef} />
      <DropInputLabel htmlFor="file">
        <div className="icon-container">
          <CreateNewFolderIcon size="large" />
        </div>
        <Typography use="subtitle1" style={{cursor: 'pointer'}}>
          Click to upload a file
        </Typography>
      </DropInputLabel>
    </DropArea>
  )
}

export default UploaderImagineStageFileSelector
