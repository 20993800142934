import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer} from 'recharts'
import {Button, Grid, Stack} from '@mui/material'
import {History as HistoryIcon} from '@mui/icons-material'
import {getBearerToken} from '../../utilities/initializeProfile'
import {H3Data} from './utils'
import ImagineCollectionHexMap from './ImagineCollectionHexMap'

const HistoryButtonContainer = styled.div`
  height: 0;
  .history-btn {
    margin-bottom: -30px;
    margin-left: 58px;
    z-index: 10000;
  }
`

const imagine_non_prod_url = 'https://v4.imagine-api.np.location360.ag/search'
const imagine_prod_url = 'https://v4.imagine-api.location360.ag/search'

export default function ImagineCollectionTimeline({collection, message}) {
  const [isLoading, setIsLoading] = useState(true)
  const [histogramData, setHistogramData] = useState([])
  const [searchHistory, setSearchHistory] = useState([])
  const [currentChartInterval, setCurrentChartInterval] = useState(0)
  const [h3PropKey, setH3PropKey] = useState(null)
  const [h3SourceData, seth3SourceData] = useState<H3Data[]>([])

  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

  const getNewestUpdatedDate = async collection => {
    const itemsIterator = await collection.fetchItems({
      limit: 1,
      sortBy: [
        {
          direction: 'desc',
          field: 'properties.datetime',
        },
      ],
    })

    for await (const item of itemsIterator) {
      let newestDate = new Date(item.properties.datetime)
      // If date of newest item is in the future or does not exist, set to today's date
      if (!(newestDate instanceof Date || newestDate > Date.now())) {
        newestDate = new Date(Date.now())
      }

      // check whether item has h3 index set
      if ((item.properties.hasOwnProperty("h3:res15") && item.properties["h3:res15"].length > 0)) {
        setH3PropKey("h3:res15")
      }

      return newestDate
    }
  }

  const getOldestUpdatedDate = async collection => {
    const itemsIterator = await collection.fetchItems({
      limit: 1,
      sortBy: [
        {
          direction: 'asc',
          field: 'properties.datetime',
        },
      ],
    })

    for await (const item of itemsIterator) {

      // check whether item has h3 index set
      if ((item.properties.hasOwnProperty("h3:res15") && item.properties["h3:res15"].length > 0)) {
        setH3PropKey("h3:res15")
      }

      let oldestDate = new Date(item.properties.datetime)
      // If date of oldest item does not exist or is older than 10 years, set oldestDate to today from 1 decade ago
      const dateFromDecadeAgo = new Date(Date.now())
      dateFromDecadeAgo.setFullYear(dateFromDecadeAgo.getFullYear() - 10)
      if (!oldestDate) {
        oldestDate = dateFromDecadeAgo
        // setReasonTimelineLimitedData(
        //     'The oldest item in this collection does not have a datetime. Not all items will be accounted for in the timeline.'
        // )
      } else if (oldestDate < dateFromDecadeAgo) {
        oldestDate = dateFromDecadeAgo
        // setReasonTimelineLimitedData(
        //     'The oldest item in this collection is older than 10 years. Not all items will be accounted for in the timeline.'
        // )
      }
      return oldestDate
    }
  }

  async function getCollectionData(): Promise<void> {
    const collection_id = collection.id

    let startDate = searchHistory[searchHistory.length - 1]['start']
    let endDate = searchHistory[searchHistory.length - 1]['end']

    //expand time range by few seconds to make sure query fetches all items
    startDate = new Date(startDate.getTime() - 30 * 1000)
    endDate = new Date(endDate.getTime() + 30 * 1000)

    const strDtStart = startDate.toISOString()
    const strDtEnd = endDate.toISOString()

    const timespanDays = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    const binInterval = Math.ceil(timespanDays / 20) + 'd' // we want maximum of 20 bars in histogram
    setCurrentChartInterval(Math.ceil(timespanDays / 20))

    const payload = {
      collections: [collection_id],
      esquery: {
        query: {
          bool: {
            must: [
              {
                range: {
                  'properties.datetime': {gt: strDtStart, lt: strDtEnd},
                },
              },
              {term: {collection: {value: collection_id}}},
            ],
            filter: [
              {
                range: {
                  'properties.datetime': {gt: strDtStart, lt: strDtEnd},
                },
              },
              {term: {collection: {value: collection_id}}},
            ],
          },
        },
        aggs: {
          temporal: {
            date_histogram: {
              field: 'properties.datetime',
              fixed_interval: binInterval,
            },
          },
        },
      },
    }

    if (h3PropKey && h3PropKey.length) {
      payload['esquery']['aggs']['h3'] = {
        terms: {
          field: 'properties.' + h3PropKey,
          size: 10000,
        },
      }
    }

    const isProd = window?.location.host.includes('velocity.ag')

    const url = isProd ? imagine_prod_url : imagine_non_prod_url
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getBearerToken()}`,
      },
    })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const data = await response.json()
    const temporalBuckets = data['aggregations']['filtered']['temporal']['buckets']

    let d = []
    for (const bucket of temporalBuckets) {
      const strDate = bucket['key_as_string']
      const docCount = bucket['doc_count']
      const date = new Date(Date.parse(strDate))

      d.push({
        count: docCount,
        date: date,
        xLabel: dateFormatter.format(date),
      })
    }

    // if h3 aggregation is also available show map
    if (data['aggregations']['filtered']['h3']) {
      const h3Buckets = data['aggregations']['filtered']['h3']['buckets']
      let h3Data: H3Data[] = []
      for (const bucket of h3Buckets) {
        const key = bucket['key']
        const docCount = parseInt(bucket['doc_count'])
        h3Data.push({
          count: docCount,
          h3index: key,
        })
      }

      seth3SourceData([...h3Data])
    }

    setHistogramData(d)
  }

  function barChartOnClick(data: any) {
    const payload = data.payload
    const startDate = payload.date
    const endDate = new Date(startDate.getTime() + currentChartInterval * 24 * 60 * 60 * 1000)

    if (searchHistory.length) {
      const lastHistoryItem = searchHistory[searchHistory.length - 1]
      // check if same histogram bar was clicked twice
      if (
        lastHistoryItem.start.getTime() === startDate.getTime() &&
        lastHistoryItem.end.getTime() === endDate.getTime()
      ) {
        console.log("double click")
        return false
      }
    }

    setSearchHistory([...searchHistory, {start: startDate, end: endDate}])
  }

  function goBackSearchHistory() {
    if (searchHistory.length > 0) {
      searchHistory.pop()
      setSearchHistory([...searchHistory])
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getNewestUpdatedDate(collection)
      .then(endDate => {
        getOldestUpdatedDate(collection).then(startDate => {
          setSearchHistory([...searchHistory, {start: startDate, end: endDate}])
          setIsLoading(false)
        })
      })
      .catch((reason: any) => {
        console.error(reason)
        setIsLoading(false)
      })
  }, [collection])

  useEffect(() => {
    console.log("search history", searchHistory)
    if (searchHistory.length) {
      const startDate = searchHistory[searchHistory.length - 1]['start']
      const endDate = searchHistory[searchHistory.length - 1]['end']
      if (startDate && endDate) {
        setIsLoading(true)
        getCollectionData()
          .then(() => {
            setIsLoading(false)
          })
          .catch(err => {
            console.error(err)
            setIsLoading(false)
          })
      }
    }
  }, [searchHistory.length])

  const CustomTooltip = ({active, payload, label}) => {
    if (active && payload && payload.length) {
      const description = `${payload[0].value} ${payload[0].value === 1 ? 'item' : 'items'}`
      return (
        <div style={{padding: '5px 15px', backgroundColor: 'rgba(255, 255, 255, 0.9)'}}>
          <p className="label">{`${label}`}</p>
          <p className="intro">{description}</p>
        </div>
      )
    }

    return null
  }

  if (!histogramData.length) {
    return <div>Loading. Please wait.</div>
  } else {
    return (
      <>
        <Stack direction="row" spacing={2}>
          <div>{message}</div>
          {searchHistory.length > 0 && (
            <HistoryButtonContainer>
              <Button
                className="history-btn"
                startIcon={<HistoryIcon />}
                onClick={goBackSearchHistory}
              >
                {' '}
                {searchHistory.length}
              </Button>
            </HistoryButtonContainer>
          )}
        </Stack>
        <Grid container>
          <Grid item xs={h3PropKey ? 6 : 12}>
            <ResponsiveContainer aspect={h3PropKey ? 2 : 5}>
              <BarChart
                data={histogramData}
                margin={{
                  top: 20,
                  right: 60,
                  left: 10,
                  bottom: 20,
                }}
              >
                <XAxis dataKey="xLabel" />
                <YAxis
                  domain={[0.8, 'auto']}
                  allowDataOverflow
                  label={{value: 'Items', angle: -90, position: 'insideLeft'}}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  dataKey="count"
                  fill="var(--mdc-theme-primary)"
                  onClick={barChartOnClick}
                  style={{cursor: 'pointer'}}
                />
              </BarChart>
            </ResponsiveContainer>
          </Grid>

          {h3PropKey && (
            <Grid item xs={6}>
              <ImagineCollectionHexMap h3SourceData={h3SourceData}></ImagineCollectionHexMap>
            </Grid>
          )}
        </Grid>
      </>
    )
  }
}

ImagineCollectionTimeline.propTypes = {
  collection: PropTypes.object.isRequired,
  message: PropTypes.string,
}
