import React, {useContext, useEffect, useState} from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'

import {itemContext} from '../context/itemContext'
import useToastNotification from '../hooks/useToastNotification'
import {telemetry} from '../utilities/telemetry'

export default function ItemMetaDataAddTagDialog(props) {
  const {successNotification, errorNotification} = useToastNotification()
  const context = useContext(itemContext)

  const {
    itemMetadata: {item, metadata},
  } = context

  const tags = metadata['tags:tags']

  const {createDialog, setCreateDialog, setTags} = props
  const [tagField, setTagField] = useState('')
  const [tagList, setTagList] = useState(tags ?? [])

  useEffect(() => {
    if (!tags) {
      return
    }
    setTagList(tags)
  }, [tags])

  function closeTagCreateDialog() {
    setCreateDialog()
    setTagField('')
  }

  async function createTag() {
    item
      .updateMetadata({properties: {['tags:tags']: [...tagList, tagField]}})
      .then(() => {
        successNotification(`Tag "${tagField}" added`)
        if (setTags) {
          setTags([...tagList, tagField])
        }
      })
      .catch(e => {
        const additionalErrorDetails = `Unable to add "${tagField}".`
        telemetry.error(e, {details: additionalErrorDetails})
        errorNotification(e.message)
      })
    closeTagCreateDialog()
  }

  return (
    <Dialog open={createDialog || false} onClose={closeTagCreateDialog}>
      <DialogTitle>Create Tag</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tags provide your item with more detail to be searched against
        </DialogContentText>

        <TextField
          autoFocus
          margin="dense"
          type="text"
          onKeyUp={e => {
            if (e.key === 'Enter') {
              createTag()
            } else {
              setTagField(e.target.value)
            }
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeTagCreateDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={createTag} color="primary">
          Create Tag
        </Button>
      </DialogActions>
    </Dialog>
  )
}
