import React, {useEffect, useRef, useState} from 'react'
import {CircularProgress, Typography} from '@mui/material'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import {useDispatch} from 'react-redux'

import {addAvailableWorkflowStages, setUploaderWorkflowStage} from '../actions/imageUploaderAction'
import DropArea from './styled/DropArea'
import DropInputLabel from './styled/DropInputLabel'
import {telemetry} from '../utilities/telemetry'
import DirectorySelector from './DirectorySelector'
import {Switch, FormControl, FormControlLabel} from '@mui/material'

/**
 * A UI element for selecting a single file. Once selected,
 * it triggers an initialize function brought in by the parent component
 * t
 */
function UploaderOrthoMosaicFileSelector(props) {
  const [initializing, setInitializing] = useState(false)
  const {initializeUploader, setCriticalError} = props
  const [directory, setDirectory] = useState(false)

  // Set initializing back to false on dismount
  useEffect(() => () => setInitializing(false), [])

  const dispatch = useDispatch()
  const setUploaderStage = stage => dispatch(setUploaderWorkflowStage(stage))
  const addWorkflowStages = stages => dispatch(addAvailableWorkflowStages(stages))
  const fileInputRef = useRef(null)

  const selectFile = () => {
    setInitializing(true)

    // When the metadata instance is set to null, it means it is ready to upload
    const {files} = fileInputRef.current
    const initUpload = files?.length > 1 ? files : files[0]
    initializeUploader(initUpload)
      .then(uploader => {
        if (!uploader) {
          console.warn('Unable to progress with ortho upload due to file initialization error.')
        } else if (uploader.metadata === null) {
          addWorkflowStages([2])
          setUploaderStage(2)
        } else {
          addWorkflowStages([1])
          setUploaderStage(1)
        }
      })
      .catch(err => {
        const details = err.message
        telemetry.error(err, {details})
        setCriticalError(details)
      })
  }

  return (
    <>
      <FormControl style={{width: '150px'}}>
        <FormControlLabel
          control={
            <Switch
              sx={{fontSize: '14px'}}
              checked={directory}
              onChange={() => setDirectory(!directory)}
            />
          }
          label="Directory"
        />
      </FormControl>
      {directory ? (
        <DirectorySelector onChange={selectFile} fileRef={fileInputRef} />
      ) : (
        <DropArea>
          <input
            type="file"
            name="file"
            id="file"
            accept="image/tiff, image/x-tiff, .las"
            onChange={selectFile}
            ref={fileInputRef}
          />
          <DropInputLabel htmlFor="file">
            {initializing ? (
              <>
                <div className="icon-container">
                  <CircularProgress />
                </div>
                <Typography use="subtitle1" style={{cursor: 'pointer'}}>
                  Uploader initializing
                </Typography>
              </>
            ) : (
              <>
                <div className="icon-container">
                  <CreateNewFolderIcon size="large" />
                </div>
                <Typography use="subtitle1" style={{cursor: 'pointer'}}>
                  Click to upload a file
                </Typography>
              </>
            )}
          </DropInputLabel>
        </DropArea>
      )}
    </>
  )
}

export default UploaderOrthoMosaicFileSelector
