import React, {createContext, useContext, useEffect, useState} from 'react'
import {imagineServiceContext} from '../context/imagineServiceContext'
import {telemetry} from '../utilities/telemetry'

const initialState = {
  albums: null,
}

const albumListContext = createContext(initialState)
const {Provider} = albumListContext

function AlbumListProvider({children}) {
  const [albums, setAlbums] = useState(null)
  const {retrieveAlbums} = useContext(imagineServiceContext)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  async function refreshAlbums() {
    setLoading(true)
    try {
      const a = await retrieveAlbums()
      setAlbums(a)
    } catch (err) {
      setError('Error refreshing albums')
      throw err
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    refreshAlbums().catch(err => telemetry.error(err, {details: 'Error refreshing albums'}))
  }, [])

  return <Provider value={{albums, refreshAlbums, loading, error}}>{children}</Provider>
}

export {albumListContext, AlbumListProvider}
