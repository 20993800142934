import React from 'react'
import PropTypes from 'prop-types'
import {TextField} from '@mui/material'

export const orange = '#FFA500'
export const red = '#b00020' // current value for var(--mdc-theme-error)

function UploaderImagineMetadataFieldNumber({
  fieldName,
  value,
  error,
  onChange,
  warning,
  integer,
  required,
}) {
  return (
    <TextField
      fullWidth
      id={`number-${fieldName}`}
      label={fieldName}
      required={required}
      value={value}
      onChange={onChange}
      type="number"
      step={integer ? 1 : 0.1}
      error={!!error}
      helperText={warning ? `(warning) ${error}` : error}
      style={{color: red}}
      variant="standard"
    />
  )
}

UploaderImagineMetadataFieldNumber.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  warning: PropTypes.bool,
  integer: PropTypes.bool,
}

UploaderImagineMetadataFieldNumber.defaultProps = {
  warning: false,
  error: '',
  value: 0,
  integer: false,
}

export default UploaderImagineMetadataFieldNumber
