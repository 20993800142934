import React, {memo, useState} from 'react'

import {Avatar, ListItem, ListItemAvatar, ListItemText} from '@mui/material'
import {purple, blue, indigo, teal, lime, amber, orange} from '@mui/material/colors'
import {HourglassEmpty} from '@mui/icons-material'

import useToastNotification from '../hooks/useToastNotification'
import {telemetry} from '../utilities/telemetry'

const colors = [purple, teal, lime, orange, indigo, blue, amber]

// @ts-ignore
const DialogAddToAlbumListItem = memo(function ({title, addItems, index, closeDialog, plural}) {
  const [loading, setLoading] = useState(false)
  const {successNotification, errorNotification} = useToastNotification()

  return (
    <ListItem
      sx={{cursor: 'pointer', '&:hover': {background: 'lightgrey'}}}
      onClick={() => {
        setLoading(true)
        addItems()
          .then(() => successNotification(`Item has been added to "${title}"`))
          .catch(e => {
            const alreadyPresentRe = /already present in Album/
            if (alreadyPresentRe.test(e)) {
              errorNotification(
                plural
                  ? `A selected item already exists in the album ${title}`
                  : `This item already exists in the album ${title}`
              )
              return
            }
            const details = `Error adding item to album "${title}"`
            errorNotification(details)
            telemetry.error(e, {details})
          })
          .finally(closeDialog)
      }}
    >
      <ListItemAvatar>
        <Avatar
          style={{
            color: colors[index % 7][600],
            backgroundColor: colors[index % 7][100],
          }}
        >
          {loading ? <HourglassEmpty /> : <>{title?.[0].toUpperCase()}</>}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={title} />
    </ListItem>
  )
})

export default DialogAddToAlbumListItem
