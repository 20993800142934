import React, {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import DialogExportAlbum from '../components/DialogExportAlbum'
import GallerySummaryAlbum from '../components/GallerySummaryAlbum'
import GalleryViewErrorCard from '../components/GalleryViewErrorCard'
import GalleryViewGrid from '../components/GalleryViewGrid'
import LoadingSpinner from '../components/LoadingSpinner'
import GalleryVisibleSchemaDialog from '../components/GalleryVisibleSchemaDialog'

import {AlbumListProvider} from '../context/albumListContext'
import {GalleryAlbumProvider} from '../context/galleryAlbumContext'
import {GalleryPageProvider} from '../context/galleryPageContext'
import {imagineServiceContext} from '../context/imagineServiceContext'
import {AlbumExportProvider} from '../context/albumExportContext'

import useGalleryPageState from '../hooks/useGalleryPageState'
import useUpdatePageVisitCount from '../hooks/useUpdatePageVisitCount'

import CommonLayout from '../layouts/CommonLayout'
import GalleryPageLayout from '../layouts/GalleryPageLayout'

import noItemsAvailableCopy from '../helpers/noItemsAvailableCopy'
import {telemetry} from '../utilities/telemetry'
import useToastNotification from '../hooks/useToastNotification'
import {UseGalleryPageState} from '../hooks/useGalleryPageState.ts'
import type {Album} from '@bayer-int/imagine-sdk-browser'

const GalleryPageAlbum = () => {
  const {album: albumName} = useParams()
  const [album, setAlbum] = useState<Album | null>(null)
  const [exportDialog, setExportDialog] = useState(false)
  const [userId, setUserId] = useState(null)
  const [schemaDialog, setSchemaDialog] = useState(false)
  const [itemListing, setItemListing] = useState(null)
  const {errorNotification} = useToastNotification()

  const {
    loading: globalLoading,
    error: globalLoadingError,
    imagineSdk,
    retrieveSession,
  } = useContext(imagineServiceContext)

  const {
    error,
    items,
    initialLoading,
    loading,
    removeItem,
    setItems,
    setNotFoundError,
    setServerError,
    hasMore,
    next,
    setNumberItemsPossible,
    numberResultingItems,
  }: UseGalleryPageState = useGalleryPageState(itemListing)

  useUpdatePageVisitCount({
    topic: 'albumVisitCount',
    pageIdentifier: albumName,
    valid: album !== null,
  })

  useEffect(() => {
    async function retrieveUsername() {
      const sessionInfo = await retrieveSession().catch(e => {
        const additionalErrorDetails = 'There was an error retrieving the session info.'
        telemetry.error(e, {details: additionalErrorDetails})
        errorNotification('Error getting user information')
        return null
      })

      if (sessionInfo?.userId) {
        setUserId(sessionInfo.userId)
      }
    }
    if (!(globalLoading || globalLoadingError)) retrieveUsername()
  }, [globalLoading, globalLoadingError])

  useEffect(() => {
    async function retrieveAlbum() {
      try {
        const albumInstance = await imagineSdk.fetchAlbum({id: albumName})

        if (albumInstance) {
          setAlbum(albumInstance)
        } else {
          setNotFoundError(albumName)
        }
      } catch (e) {
        const additionalErrorDetails = `Unable to retrieve album '${albumName}.`
        telemetry.error(e, {details: additionalErrorDetails})
        setServerError()
      }
    }

    if (!globalLoading && !globalLoadingError) retrieveAlbum()
    if (globalLoadingError) setServerError()
  }, [globalLoading, globalLoadingError])

  useEffect(() => {
    async function getItemListingAndCount() {
      try {
        const itemListing = album.fetchItems({limit: Infinity})
        setItemListing(itemListing)
        const count = await itemListing.count()
        setNumberItemsPossible(count)
      } catch (e) {
        const additionalErrorDetails = `Error in retrieving the itemListing or count for project ${album.id}`
        telemetry.error(e, {details: additionalErrorDetails})
      }
    }
    if (album) getItemListingAndCount()
  }, [album])

  useEffect(() => {
    if (itemListing && next) next('down')
  }, [itemListing])

  async function removeItemsFromList(itemsForRemoval) {
    const indexesForRemoval = itemsForRemoval.map(i => i.id)
    const newItems = items.filter(item => indexesForRemoval.indexOf(item.id) === -1)

    setItems(newItems)
  }

  if (error && error.title !== noItemsAvailableCopy) {
    return (
      <CommonLayout>
        <GalleryPageLayout>
          <GalleryViewErrorCard {...error} />
        </GalleryPageLayout>
      </CommonLayout>
    )
  }

  if (globalLoading || initialLoading) {
    return (
      <CommonLayout>
        <GalleryPageLayout>
          <LoadingSpinner message="Loading album..." />
        </GalleryPageLayout>
      </CommonLayout>
    )
  }

  const summary = (
    <>
      <GallerySummaryAlbum
        canAlter={album?.createdBy === userId}
        album={album}
        count={numberResultingItems}
        description={album?.description}
        title={album?.title}
        triggerExportDialog={() => setExportDialog(true)}
        openSchemaDialog={() => setSchemaDialog(true)}
      />
      <GalleryVisibleSchemaDialog onClose={() => setSchemaDialog(false)} open={schemaDialog} />
    </>
  )

  return (
    <CommonLayout>
      <GalleryAlbumProvider album={album} removeItemsFromList={removeItemsFromList}>
        <GalleryPageProvider
          album={album}
          type="album"
          loading={loading}
          items={items}
          removeItem={removeItem}
          hasMore={hasMore}
          next={next}
        >
          <AlbumListProvider>
            <GalleryPageLayout summaryComponent={summary}>
              {exportDialog && (
                <AlbumExportProvider>
                  <DialogExportAlbum
                    album={album}
                    items={items}
                    closeDialog={() => setExportDialog(false)}
                  />
                </AlbumExportProvider>
              )}
              <GalleryViewGrid
                canRemoveAlbumItem={album?.createdBy === userId}
                removeItemsFromState={removeItemsFromList}
              />
            </GalleryPageLayout>
          </AlbumListProvider>
        </GalleryPageProvider>
      </GalleryAlbumProvider>
    </CommonLayout>
  )
}

export default GalleryPageAlbum
