import React, {useContext, useEffect, useRef, useState} from 'react'
import {telemetry} from '../utilities/telemetry'
import {CircularProgress} from '@mui/material'

import {imagineServiceContext} from '../context/imagineServiceContext'
import useAdvancedSearch from '../hooks/useAdvancedSearch'
import mapSearchParametersToFetchItemsOptions from '../utilities/advancedSearchParameters'
import AdvancedSearchResultsPreviewText from './AdvancedSearchResultsPreviewText'
import validBoundingBox from '../helpers/validBoundingBox'

/**
 * Determines the count of a potential advanced search, and generates the copy
 *   of that search result to an subcomponent that handles the visualization
 */
function AdvancedSearchResultsPreview() {
  const [resultsText, setResultsText] = useState(null)
  const [error, setError] = useState(null)

  const {imagineSdk} = useContext(imagineServiceContext)
  const {searchAttributes} = useAdvancedSearch()
  const mounted = useRef(true)
  const [loading, setLoading] = useState(false)

  useEffect(function () {
    // Make clear that the component is no longer mounted via ref on unmount
    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    async function retrieveSearchResults() {
      const searchPayload = mapSearchParametersToFetchItemsOptions(searchAttributes)

      let count
      try {
        const response = imagineSdk.fetchItems({...searchPayload, limit: 1})
        count = await response.count()
      } catch (e) {
        const additionalErrorDetails = 'There was an error in retrieving advanced search results.'
        telemetry.error(e, {details: additionalErrorDetails})
        setError(e)
        return
      }

      setError(null)

      if (!mounted.current) return

      if (isFinite(count)) {
        setResultsText(
          `This search will return ${new Intl.NumberFormat('en-us').format(count)} results.`
        )
      } else {
        setResultsText(`This search will return more than 10,000 results.`)
      }
    }

    const {bbox, fromDate, keywords, toDate, tags, filterText} = searchAttributes
    const noBoundingBox = !validBoundingBox(bbox) || bbox.join(' ') === '0 0 0 0'

    const noCollections = searchAttributes.collections.length === 0
    const noDateSelected = !(fromDate || toDate)
    const noKeywords = keywords.length === 0
    const noProjects = searchAttributes.projects.length === 0
    const noProperties = !searchAttributes.properties.length
    const noTags = !tags.length
    const noFilterText = !filterText?.length

    if (
      noBoundingBox &&
      noCollections &&
      noDateSelected &&
      noKeywords &&
      noProjects &&
      noTags &&
      noProperties &&
      noFilterText
    ) {
      setResultsText(null)
    } else if (imagineSdk) {
      setLoading(true)
      retrieveSearchResults().finally(() => setLoading(false))
    }
  }, [searchAttributes, imagineSdk])

  if (loading)
    return (
      <div onClick={() => setLoading(false)} style={{display: 'flex'}}>
        <AdvancedSearchResultsPreviewText
          label="Loading search result size"
          containerStyles={{marginRight: '10px'}}
        />
        <CircularProgress size={20} />
      </div>
    )
  if (error) return <AdvancedSearchResultsPreviewText label="Unable to preview results" />
  if (!resultsText) return <AdvancedSearchResultsPreviewText label="" />
  return <AdvancedSearchResultsPreviewText label={resultsText} />
}

export default AdvancedSearchResultsPreview
