import React, {useContext, useEffect, useState} from 'react'
import {Table, TableRow, TableCell} from '@mui/material'

import {itemContext} from '../context/itemContext'
import ItemMetadataAssetAccordion from './ItemMetadataAssetAccordion'
import ItemMetadataHeaderAccordion from './ItemMetadataHeaderAccordion'
import viewer from '../utilities/imageViewerService'
import validBoundingBox from '../helpers/validBoundingBox'
import {telemetry} from '../utilities/telemetry'

export async function isOutOfRange(url, mb) {
  const bytes = mb * 1000000 + 1
  const head = await fetch(url, {
    headers: {Range: `bytes=${bytes}-${bytes}`},
  })

  return head.status !== 416
}

export async function assetTooLarge(asset, mb) {
  try {
    return await isOutOfRange(await asset.fetchDownloadUrl(), mb)
  } catch (err) {
    return false
  }
}

/**
 * A series of metadata tables for each asset belonging to an item.
 * The tables also collapse and expand.
 */
function ItemMetadataAssetList() {
  const [openAccordionPanel, setOpenAccordionPanel] = useState(false)

  const {itemMetadata, setItemMetadata} = useContext(itemContext)
  const {assets} = itemMetadata

  useEffect(() => {
    const viewableAssetList = async assetList => {
      const viewableAssets = []
      assetList.forEach(async asset => {
        const validImageByHref = viewer.ImageViewer.isSupported(asset?.href)

        const validImageByAssetType =
          asset.mediaType && viewer.ImageViewer.isSupported(asset?.mediaType)

        const supportedFileType = validImageByHref || validImageByAssetType

        let disabledReason
        let disabledPreviewReason

        if (supportedFileType === 'TMS' && !validBoundingBox(itemMetadata.bbox)) {
          disabledReason = 'Add a geometry to this item to enable TMS preview'
        }

        if (!supportedFileType) {
          disabledPreviewReason = 'Image preview does not support the file format'
        } else if (asset.size > 30e6) {
          disabledPreviewReason = 'Image preview does not support files over 30 MB'
        } else if (
          asset.size === undefined &&
          supportedFileType !== 'TMS' &&
          (await assetTooLarge(asset, 30))
        ) {
          disabledPreviewReason = 'Image preview does not support files over 30 MB'
        }

        let isThumbnail = asset.roles?.includes('thumbnail')

        const isEightBandAsset =
          itemMetadata.extensions.includes('loc360sp_ps_8bsr') && asset.id.includes('COG')

        if (!isThumbnail && !disabledReason && !disabledPreviewReason && !isEightBandAsset) {
          viewableAssets.push(asset)
        }
      })

      return viewableAssets
    }

    if (assets) {
      viewableAssetList(assets)
        .then(myViewableAssets =>
          setItemMetadata({...itemMetadata, viewableAssets: myViewableAssets})
        )
        .catch(err => telemetry.error(err, {details: 'Error calculating viewable assets.'}))
    }
  }, [assets])

  if (!assets || assets.length === 0) return <></>

  return (
    <>
      <ItemMetadataHeaderAccordion header="Assets">
        {assets.length > 0 ? (
          assets
            .sort((a, b) => a.id.localeCompare(b.id))
            .map((asset, index) => {
              const {title} = asset

              return (
                <ItemMetadataAssetAccordion
                  key={`asset-accordion-${index}-${title}`}
                  name={asset.id}
                  asset={asset}
                  index={index}
                  openAccordionPanel={openAccordionPanel}
                  setOpenAccordionPanel={setOpenAccordionPanel}
                />
              )
            })
        ) : (
          <Table style={{minWidth: 450}} aria-label="simple table">
            <tbody>
              <TableRow>
                <TableCell>No assets available in this item for download</TableCell>
              </TableRow>
            </tbody>
          </Table>
        )}
      </ItemMetadataHeaderAccordion>
    </>
  )
}

export default ItemMetadataAssetList
