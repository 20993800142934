import {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {imagineServiceContext} from '../context/imagineServiceContext'
import {
  addAvailableWorkflowStages,
  indicateMetadataValidationResults,
  indicateWorkflowFailure,
  setAvailableWorkflowStages,
} from '../actions/imageUploaderAction'
import {telemetry} from '../utilities/telemetry'

/**
 * Carries out validation process. Sends results to the app state
 * through redux
 *
 * Returns:
 * ready: Used to indicate whether user can validate
 * validate: trigger function
 */
function useImageUploaderValidate() {
  const [collections, setCollections] = useState(null)
  const [validating, setValidating] = useState(false)
  const {selectedCollection, metadataContent} = useSelector(state => state.imageUploader)
  const dispatch = useDispatch()
  const {imagineSdk, retrieveCollections} = useContext(imagineServiceContext)

  /** Retrieve collections on load */
  useEffect(() => {
    async function retrieveStacCollections() {
      const stacCollections = retrieveCollections()
      setCollections(stacCollections)
    }
    retrieveStacCollections()
  }, [])

  /** Engages validation process */
  useEffect(() => {
    async function validateMetadata() {
      const collection = await imagineSdk.fetchCollection({id: selectedCollection})

      let validationResults = null
      try {
        const collectionSchema = await collection.itemSchema()

        const validationResults = collectionSchema.validateProperties(metadataContent.properties)
        telemetry.info('Validating properties.', {
          type: 'upload',
          subtype: 'ImagineServiceUploader',
          metadata: {
            metadataContent,
            validationResults: JSON.parse(JSON.stringify(validationResults)),
          },
        })

        dispatch(indicateMetadataValidationResults(validationResults))

        if (!validationResults.length) {
          dispatch(addAvailableWorkflowStages([1, 2, 3]))
        } else {
          setAvailableWorkflowStages([1, 2])
        }
        setValidating(false)
      } catch (e) {
        const additionalErrorDetails =
          'Unable to validate data due to an error. Please contact Location360 Support.'
        telemetry.error(e, {
          details: additionalErrorDetails,
          type: 'upload',
          subtype: 'ImagineServiceUploader',
          metadata: {
            collection: collection.id,
            metadataContent,
            validationResults,
          },
        })

        dispatch(indicateWorkflowFailure(additionalErrorDetails))
        setValidating(false)
      }
    }

    if (validating) {
      validateMetadata().catch(err => {
        telemetry.error(err)
        dispatch(indicateWorkflowFailure(`Error validating data: ${err.message}`))
      })
    }
  }, [validating])

  /** Triggers validation */
  function validate() {
    setValidating(true)
  }

  const ready = !!collections && !validating && !!selectedCollection

  return {ready, validate}
}

export default useImageUploaderValidate
