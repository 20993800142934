import {telemetry} from './telemetry'

const concatDirection = (direction: string, reverse: boolean, rows: any) => (prev: any) =>
  direction === 'up' || direction === 'left'
    ? reverse
      ? [...prev, ...rows]
      : [...rows, ...prev]
    : reverse
    ? [...rows, ...prev]
    : [...prev, ...rows]

export const createNextFactory =
  ({itemListing, setLoading, setData, offset, reverse, hasMore, setHasMore}) =>
  async (direction: string) => {
    if (!itemListing) {
      return
    }
    try {
      setLoading(true)
      const rows = []
      for (let i = 0; i < offset; i++) {
        if (hasMore) {
          let {value, done} = await itemListing.next()
          if (done) {
            setHasMore(false)
            break
          }
          rows.push(value)
        }
      }
      setData(concatDirection(direction, reverse, rows))
    } catch (err) {
      telemetry.error(err, {details: 'Error in infinite scroll'})
    } finally {
      setLoading(false)
    }
  }
