import {saveAs} from 'file-saver'
import {telemetry} from './telemetry'

/**
 * Initiates a download of an imagine asset. Offers an error download on failure.
 *
 * @param {string} itemId Imagine Item UUID
 * @param {*} asset Asset as provided by the Imagine JS SDK
 * Doc: https://github.platforms.engineering/pages/location-360/imagine-javascript-sdk/classes/asset.html
 */
async function downloadImagineAsset(asset, itemId) {
  return asset
    .fetchDownloadUrl()
    .then(url => {
      const fileName = `${itemId}-${asset.name}`
      saveAs(url, fileName)
    })
    .catch(error => {
      const assetName = asset?.name ? `${asset.name} (${itemId})` : `${itemId}`
      telemetry.error(error, {details: `Error downloading imagine asset: ${assetName}`})
      throw error
    })
}

export default downloadImagineAsset
