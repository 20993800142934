import React, {useState} from 'react'
import CloseIcon from '@mui/icons-material/Close'
import WarningIcon from '@mui/icons-material/Warning'
import {IconButton} from '@mui/material'
import moment from 'moment'
import {telemetry} from '../utilities/telemetry'

export function MaintenanceBanner({title, message}: {title?: string; message?: string}) {
  const closedRecently = maintenanceBannerClosedRecently()
  const [open, setOpen] = useState(!closedRecently)

  function closeBanner() {
    setOpen(false)
    onCloseMaintenanceBanner()
  }

  if (open && (title || message)) {
    return (
      <div
        style={{
          padding: '8px',
          background: '#fff4e5',
          minHeight: '56px',
          display: 'flex',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <WarningIcon />
            <span style={{marginLeft: '8px'}}>
              <b>{title ?? message}</b>
            </span>
          </div>
          {message && <span style={{marginTop: '8px'}}>{message}</span>}
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <IconButton onClick={closeBanner}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    )
  }

  return undefined
}

const MAINTENANCE_BANNER_LAST_CLOESD = 'MAINTENANCE_BANNER_LAST_CLOESD'

export function maintenanceBannerClosedRecently(): boolean {
  try {
    const lastClosedTimeStr = window.localStorage.getItem(MAINTENANCE_BANNER_LAST_CLOESD)
    const lastClosedTime = moment(lastClosedTimeStr)
    const now = moment()
    const isClosedRecently = now.diff(lastClosedTime, 'hour') < 10
    return isClosedRecently
  } catch (err) {
    telemetry.error(err, {details: 'Error checking for recent maintenance banner close time'})
  }
}

export function onCloseMaintenanceBanner(): void {
  try {
    const lastClose = moment().toISOString()
    window.localStorage.setItem(MAINTENANCE_BANNER_LAST_CLOESD, lastClose)
  } catch (err) {
    telemetry.error(err, {details: 'Error closing maintenance banner'})
  }
}
