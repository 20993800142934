import React, {useContext, useEffect, useRef} from 'react'

import DirectoryLayout from '../layouts/DirectoryLayout'
import CommonLayout from '../layouts/CommonLayout'
import {imagineServiceContext} from '../context/imagineServiceContext'
import {telemetry} from '../utilities/telemetry'

import useDirectoryPageState from '../hooks/useDirectoryPageState'
import {asyncThrottle} from '../utilities/async'
import type {EntitlementLevel, Project} from '@bayer-int/imagine-sdk-browser'
import useToastNotification from '../hooks/useToastNotification'

const DIRECTORY_PAGE_PROJECTS_CACHE_KEY = `DIRECTORY_PAGE_PROJECTS_CACHE_KEY`

async function projectToDisplayElement(project: Project): Promise<DisplayElement> {
  const getEntitlements = await project.entitlements()
  const userEntitlements = []
  if (getEntitlements.currentUserHasEntitlement('ADMIN' as EntitlementLevel))
    userEntitlements.push('ADMIN')
  if (getEntitlements.currentUserHasEntitlement('DELETE' as EntitlementLevel))
    userEntitlements.push('DELETE')
  if (getEntitlements.currentUserHasEntitlement('PUBLISH' as EntitlementLevel))
    userEntitlements.push('PUBLISH')
  if (getEntitlements.currentUserHasEntitlement('DOWNLOAD' as EntitlementLevel))
    userEntitlements.push('DOWNLOAD')

  const itemsIterator = project?.fetchItems({limit: 1})

  const projectDisplayElement: DisplayElement = {
    name: project.id,
    title: project.title,
    entitlements: userEntitlements,
    description: '',
    count: await itemsIterator.count().catch(e => {
      const additionalErrorDetails = `Unable to retrieve count for project "${project.title}".`
      telemetry.error(e, {details: additionalErrorDetails})
      return null
    }),
    thumbnail: null,
  }

  if (projectDisplayElement.count) {
    // Fetch thumbnail URL for a project by retrieving a single item within that project
    for await (const item of itemsIterator) {
      const thumbnailAsset = await item.fetchAsset({role: 'thumbnail'}).catch(e => {
        // ignore
      })
      const thumbnailUrl = await thumbnailAsset?.fetchDownloadUrl().catch(e => {
        // ignore
      })
      projectDisplayElement.thumbnail = thumbnailUrl
    }
  }

  return projectDisplayElement
}

export type DisplayElement = {
  id?: string
  name: string
  version?: string
  title: string
  entitlements: string[]
  description: string
  count: number | null
  thumbnail: string
}

export type DirectoryPageCacheData = {cnt: number; displayElements: DisplayElement[]}

/**
 * Data loading for the projects. Uses the directory layout components
 */
function DirectoryPageProjects() {
  const {
    loading: globalLoading,
    error: globalLoadingError,
    retrieveProjects,
  } = useContext(imagineServiceContext)

  const {
    state,
    dispatch,
    setError,
    setData,
    incrementNumberItemsPopulated,
    itemPopulatingComplete,
  } = useDirectoryPageState()
  const mounted = useRef(false)
  const abortController = useRef(new AbortController())
  const {filter} = state
  const {errorNotification, warningNotification} = useToastNotification()

  async function onAddData(project: Project) {
    const displayElement = await projectToDisplayElement(project)
    const allElements = (state.data ?? []).concat(displayElement)
    setData(allElements)
    window.localStorage.setItem(
      DIRECTORY_PAGE_PROJECTS_CACHE_KEY,
      JSON.stringify({
        cnt: allElements.length,
        projectElements: allElements,
      })
    )
  }

  useEffect(function () {
    mounted.current = true
    // Make clear that the component is no longer mounted via ref on unmount
    return () => {
      mounted.current = false
      abortController?.current?.abort()
    }
  }, [])

  useEffect(() => {
    const gatherProjectElements = async () => {
      let projectsArray: Project[]
      let cachedData: DirectoryPageCacheData

      try {
        const cachedDataStr = window.localStorage.getItem(DIRECTORY_PAGE_PROJECTS_CACHE_KEY)
        if (cachedDataStr) {
          cachedData = JSON.parse(cachedDataStr)
          if (cachedData.displayElements?.length) {
            dispatch({
              type: 'set-max-count',
              maxCount: cachedData.cnt,
            })
            if (mounted.current) {
              setData(cachedData.displayElements ?? [])
              incrementNumberItemsPopulated()
            }
          } else {
            cachedData = null
          }
        }
      } catch (err) {
        const additionalErrorDetails = 'There was an error loading cached project data.'
        telemetry.error(err, {details: additionalErrorDetails})
      }

      try {
        projectsArray = await retrieveProjects()
      } catch (e) {
        const additionalErrorDetails = 'There was an error retrieving projects.'
        telemetry.error(e, {details: additionalErrorDetails})
        if (cachedData) {
          warningNotification(
            'Error checking for project updates. The server may be having trouble.'
          )
        } else {
          errorNotification('Error loading projects. The server may be having trouble.')
        }

        return
      }

      if (projectsArray.length === 0) setError('No projects are available to retrieve from Imagine')

      try {
        const projectElements = []
        if (projectsArray.length > 0 && mounted.current)
          dispatch({
            type: 'set-max-count',
            maxCount: projectsArray.length,
          })

        // eslint-disable-next-line
        const projectQueryFns = projectsArray.map(project => {
          return async () => {
            const displayElement = await projectToDisplayElement(project)
            projectElements.push(displayElement)
            if (!cachedData && mounted.current) {
              setData(projectElements)
              incrementNumberItemsPopulated()
            }
          }
        })
        const projectQueries = await asyncThrottle(projectQueryFns, {
          limit: 20,
          abortController: abortController.current,
          checkDelay: 20,
        })
        Promise.allSettled(projectQueries)
          .then(() => {
            if (mounted.current) {
              setData(projectElements)
              itemPopulatingComplete()
              window.localStorage.setItem(
                DIRECTORY_PAGE_PROJECTS_CACHE_KEY,
                JSON.stringify({
                  cnt: projectsArray.length,
                  displayElements: projectElements,
                })
              )
            }
          })
          .catch(e => telemetry.error(e))
      } catch (e) {
        if (mounted.current) {
          const details = 'There was an error loading projects.'
          telemetry.error(e, {details})
          errorNotification(details)
        }
      }
    }

    if (!(globalLoading || globalLoadingError) && mounted.current) gatherProjectElements()
  }, [globalLoading, globalLoadingError, mounted.current])

  const filteredContent =
    state.data?.filter(
      ({name, title}) =>
        title?.toLocaleLowerCase()?.includes(filter?.toLocaleLowerCase()) ||
        name?.toLocaleLowerCase()?.includes(filter?.toLocaleLowerCase())
    ) ?? []

  return (
    <CommonLayout>
      <DirectoryLayout
        filteredContent={filteredContent}
        loading={state.loading}
        error={state.error || globalLoadingError}
        populating={state.itemsPopulated < state.maxCount}
        numPossibleElements={state.data?.length ?? 0}
        maxCount={state.maxCount}
        contentType="projects"
        onAddData={onAddData}
      />
    </CommonLayout>
  )
}

export default DirectoryPageProjects
